<template>
  <v-hover v-slot="{ hover }">
    <v-row
      class="pb-0 my-0 mb-0 px-0"
      :class="{ light_grey: hover }"
      wrap
      no-gutters
      justify="start"
      align="start"
    >
      <v-col cols="12" class="pa-0">
        <v-card flat class="pa-0">
          <!-- FILE -->
          <v-list dense class="pa-0">
            <v-list-item
              style="align-items: normal; cursor: default"
              class="pa-0 px-1 ultraDenseListItem"
              @click="() => {}"
            >
              <v-list-item-icon class="justify-start mr-0">
                <img height="14" width="14" :src="fileIconFromUrl(fileName)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ fileName }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon small @click="deleteFile">
                  <v-icon small>mdi-delete-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
import files from "@/mixins/files.js";

export default {
  name: "feedItemFileUpload",
  mixins: [files],
  components: {},
  props: {
    file: {
      required: true,
      type: [Object, File],
    },
  },
  data() {
    return {
      fileName: "",
    };
  },
  computed: {},
  methods: {
    deleteFile() {
      this.$emit("deleteFile", this.file);
    },
  },
  created() {
    this.fileName = this.file.name
      ? this.file.name
      : this.fileNameFromUrl(this.file.file_url);
  },
};
</script>


