import { Uploader } from "@/services/active-storage.api.js";
export default {
  data() {
    return {
      uploadUrl: `${process.env.VUE_APP_API_URL}/rails/active_storage/direct_uploads`,
      uploading: false,
      uploadProgressTotal: 0,
      uploadProgressDone: 0,
      uploadFileName: "",
      uploadSize: "",
      uploadComplete: false,
      uploadFailed: false,
      uploadedFile: null,
      uploadedBlob: null
    }
  },
  computed: {
    progressColor() {
      if (this.uploadComplete) return "success";
      else return this.progressValue == 0 ? "accent" : "button_blue";
    },
    progressValue() {
      if (this.uploadProgressTotal == 0) return 0;
      else
        return Math.round(
          (this.uploadProgressDone / this.uploadProgressTotal) * 100
        );
    },
    uploadTitle() {
      if (this.uploadComplete) return this.$t("misc.uploadComplete");
      else
        return this.progressValue == 0
          ? this.$t("misc.initializingUpload")
          : this.$t("misc.uploadInProgress");
    },
  },
  methods: {
    directUploadWillStoreFileWithXHR(request) {
      request.upload.addEventListener("progress", (event) => {
        this.uploadProgressTotal = event.total;
        this.uploadProgressDone = event.loaded;
      }
      );
    },
    async upload(file) {
      const uploader = new Uploader(file);
      this.uploading = true;
      this.uploadFileName = file.name;
      this.uploadSize = file.size;
      uploader.directUploadWillStoreFileWithXHR = this.directUploadWillStoreFileWithXHR;

      try {
        const blob = await uploader.create();

        this.uploadedFile = file;
        this.uploadedBlob = blob;
        this.uploading = false;
        this.uploadComplete = true;
        this.$emit("uploadCompleted", { file, blob });
      } catch (error) {
        this.sendError(error);
        this.uploading = false;
        this.uploadFailed = true;
      }
    },
  }
};
