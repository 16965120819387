<template>
  <div class="grid_task textCardTask editor__content feed_item_html mt-1">


    <v-btn small  style="top: -5px" icon :color="taskDone ? 'success' : '#aaa'"  @click.stop="updateTask">
      <v-icon size="20">
        {{taskDone ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}
      </v-icon>
    </v-btn>
    <div class="textCardTask pl-2" v-html="task.content" />

    <v-col class="py-0">
      <userAvatarGroup
      style="top: -2px"
        v-if="task.users.length"
        :limit="2"
        :users="task.users"
        :size="20"
        :disabledMenu="false"
    /></v-col>
    <v-col class="py-0">
      <v-chip
        x-small
        color="light_grey"
        class="grey--text px-2"
        v-if="task.due_date"
      >
        <v-icon x-small style="left: -3px" color="grey">
          mdi-calendar-check
        </v-icon>
        {{ friendlyDate(task.due_date) }}
      </v-chip>
    </v-col>
    <v-col class="py-0">
      <task-priority :value="task.priority" />
    </v-col>
  </div>
</template>

<script>
import UPDATE_FEED_ITEM from "@/graphql/feed_items/update.gql";
import date from "@/mixins/date";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import taskPriority from "./task_priority.vue";

export default {
  name: "task_show",
  mixins: [date],
  components: { userAvatarGroup, taskPriority },
  props: {
    task: {
      type: Object,
      required: true,
    },
    currentMeetingId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      taskDone: false,
    };
  },
  computed: {},
  methods: {
    updateTask() {
      //Toogle task state
      this.taskDone = !this.taskDone;
      this.$apollo
        .mutate({
          mutation: UPDATE_FEED_ITEM,
          variables: {
            id: this.task.id,
            done: this.taskDone,
            last_update_at_meeting_id: this.currentMeetingId,
          },
        })
        .then(({ data }) => {})
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
  },
  watch: {
    task() {
      this.taskDone = this.task.done;
    },
  },
  mounted() {
    this.taskDone = this.task.done;
  },
};
</script>

<style scoped>
.grid_task {
  display: grid;
  grid-template-columns: 28px 1fr 95px 90px 90px;
  grid-gap: 0px;
  grid-auto-rows: auto;
  padding-bottom: 2px;
  padding-top: 2px;
  align-items: flex-start;
  align-content: start;
  justify-content: space-between;
}

.grid_checkbox {
  justify-self: center;
  align-self: start;
}
</style>
