<template>
  <v-card
    flat
    color="white"
    :class="{
      'rounded-xl': !showFileViewerCol,
      'rounded-lg': showFileViewerCol,
    }"
    class="mx-0 elevation-4"
     
     
  >
    
      <fileViewerWrapper
        @close="close"
        :openedFile="fileToShow"
        :filesGroupedByCard="filesGroupedByCard"
        :fullscreen="fullscreen"
        @toggleFullscreen="fullscreen = !fullscreen"
      >
        <!-- <template slot="toolbar">
          <v-btn
            text
            small
            :icon="$vuetify.breakpoint.smAndDown"
            @click="toggleDisplayOnlyDraft()"
            v-show="!$vuetify.breakpoint.mdAndDown "
            v-if="!fullscreen"
          >
            <v-icon small>{{
              focusFileViewerCol
                ? "mdi-view-split-vertical"
                : "mdi-arrow-expand-horizontal"
            }}</v-icon>

            <span class="pl-1 hidden-sm-and-down">
              {{
                focusFileViewerCol
                  ? $t("textEditor.sideBySide")
                  : $t("textEditor.focusEditor")
              }}</span
            >
          </v-btn>
        </template> -->
      </fileViewerWrapper>
    
  </v-card>
</template>

<script>
import fileViewerWrapper from "../file_viewer/file_viewer_wrapper";

export default {
  name: "file_viewer_column",
  mixins: [],
  components: {
    fileViewerWrapper,
  },
  sockets: {},
  props: {
    showFileViewerCol: {
      type: Boolean,
      required: false,
    },
    focusFileViewerCol: {
      type: Boolean,
      required: false,
    },
    fileToShow: {
      type: Object,
      default: () => {},
    },
    filesGroupedByCard: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fullscreen: false
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    toggleDisplayDraftSection() {
      this.$emit("setShowFileViewerCol", !this.showFileViewerCol);
    },
    toggleDisplayOnlyDraft() {
      this.$emit("setFocusFileViewerCol", !this.showFileViewerCol);
    },
  },
  created() {},
};
</script>

 