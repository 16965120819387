<template>
  <div>
    
    <v-list dense class="pa-0" color="light_grey">
      <v-list-item dense   @click.stop="openDocument()" class="my-0">
        <v-list-item-icon class="mr-2" >
          <img
            height="20"
            style="position: relative; top: 2px"
            :src="fileIconFromExtension(fileExtensionFromUrl(file.file_url))"
          />
        </v-list-item-icon>
        <v-list-item-content class="py-0">
          <v-list-item-title
            v-text="fileNameFromUrl(file.file_url)"
          ></v-list-item-title>

          <!-- <v-list-item-subtitle
            v-if="file.file_size"
            v-text="
              file.file_size ? file.file_size : 'File size not defined, to fix.'
            "
          ></v-list-item-subtitle> -->
        </v-list-item-content>

        <v-list-item-action class="my-0">

          <v-tooltip left>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                @click.stop=""
                icon
                small
                download
                :href="file.file_url"
                v-on="{ ...tooltip }"
                 target="_blank"
              >
                <v-icon small color="grey"> mdi-download </v-icon>
              </v-btn>
            </template>

            <span> {{ $t("misc.downloadFile") }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <!-- <vueDocPreview v-if="displayFile" :value="file.file_url" type="office" /> -->
    <!-- <v-hover v-slot="{ hover }">
    <v-card
      :ripple="false"
      flat
      height="76px"
      class="ma-4 d-flex justify-space-between align-center"
      :color="hover ? 'button_blue' : 'white'"
       :dark="hover"
      @click="goToMeeting()"
    >
      <v-card-title>
       {{file.file_name}}

      </v-card-title>
    </v-card></v-hover
  > -->
  </div>
</template>

<script>
import date from "@/mixins/date";
import files from "@/mixins/files";
import vueDocPreview from "vue-doc-preview";

export default {
  name: "fileItem",
  mixins: [date, files],
  components: { vueDocPreview },
  props: {
    file: {
      type: Object,
    },
     mobileVersion: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      
    };
  },
  methods: {
    openDocument() {
      console.log("emit file to open")
      this.$emit("openFile", this.file)
    },
  },
  computed: {},
  mounted() {
    
  },
};
</script>

