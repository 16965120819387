<template>
  <div class="light_grey_background" style="height: 100%">
    <headerToolbar toolbarTitleClass="ml-0"   color="white" divider preventOrgSwitch>
      <template v-slot:toolbarIcon>
        <v-app-bar-nav-icon @click="goToFollowUpsIndex()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-app-bar-nav-icon>
      </template>

      <template v-slot:toolbarTitle>


          <!-- NAVBAR -->
    <topicToolbar
      :subject="followUp.name"
      :description="followUp.description"
      :users="followUpUsers"
      :owner="followUp.user"
      :meeting_topics="[]"
      :assetsFiles="[]"
      :assetsUrls="[]"
      backIcon="mdi-close"
      @clickOnBackIcon="goToFollowUpsIndex"
      :followUp="followUp"
      :displayFollowupIcon="true"
      :divider="false"
    >
      <template slot="followupAddUsers">
        <v-menu v-model="showShareMenu" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              rounded
              small
              active-class="active_button"
              ><v-icon class="pr-2" color="button_blue" small
                >mdi-share-variant</v-icon
              >{{ $t("followUps.shareFollowUp") }}

              <!-- <userAvatarGroup
                class="hidden-sm-and-down"
                style="left: -25px"
                :users="followUpUsers"
                :size="18"
                :label="$t('followUps.followupMembers')"
              /> -->
            </v-btn>
          </template>
          <v-card
            flat
            class="px-4 py-0"
            width="360"
            color="white"
            height="100%"
            style="max-height: 80vh"
          >
            <v-card-title class="pa-0">
              <v-row wrap no-gutters>
                <v-col cols="12" class="pa-0">
                  <v-list dense class="pa-0">
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("followUps.addOrRemoveUsers") }}:
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <searchAndSelectUsers
                    :selectedUsersIds="followUpUserIds"
                    @onUserSelected="addUserToFollowUp"
                    @onUserUnselected="excludeUserFromFollowUp"
                    :selectedMessage="$t('followUps.followupMember')"
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text
              class="pa-0 pt-0"
              style="height: calc(100% - 112px); overflow-y: auto"
            >
              <v-list dense two-lines class="py-0">
                <userItem
                  v-for="(user, userIndex) in followUpUsers"
                  :key="user && user.id ? user.id  : '_deleted_' + userIndex"
                  :user="user"
                >
                  <template v-slot:action>
                    <v-tooltip left color="error">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          @click="excludeUserFromFollowUp(user)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="error" small>mdi-account-minus</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("followUps.removeMemberAlertTitle") }}</span>
                    </v-tooltip>
                  </template>
                </userItem>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </topicToolbar>
      </template>
    </headerToolbar>
     <loadingOverlay
          :message="$t('followUps.loadingFollowup')"
          :display="loading"
        />
    <!-- <headerToolbar>
      <template v-slot:toolbarTitle>
        <b>{{ followUpName }}</b>
      </template>
    </headerToolbar> -->


    <v-container
      fluid
      style="height: calc(100vh - 96px); overflow-y: hidden"
      class="py-0"
    >
      <v-row
        wrap
        no-gutters
        style="height: 100%; max-width: 1280px"
        class="mx-auto justify-center text-xs-center"
      >

        <cardsIndex v-if="!loading" :followUpId="followUpId" @showFile="showFile" />
      </v-row>
    </v-container>

    <!-- <v-card
      class="d-flex justify-space-between mb-6"
      color="grey lighten-4"
      flat
      tile
    > -->
    <!-- BACK TO FOLLOW UP INDEX -->
    <!-- <v-flex class="pa-3 d-flex justify-begin align-center">
        <v-tooltip left color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="$router.push({ name: 'followups' })"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("misc.back") }}</span>
        </v-tooltip>
      </v-flex>

      <v-flex class="pa-3 d-flex justify-end align-center">
        <userAvatarGroup :limit="10" :users="followUpUsers" :size="24" /> -->
    <!-- class="hidden-md-and-down" -->
    <!-- </v-flex> -->

    <!-- ADD / REMOVE USERS AND GROUPS -->
    <!-- <v-flex class="pa-2 d-flex justify-center align-center" outlined tile>
        <searchAndSelectUsers
          :selectedUsersIds="followUpUserIds"
          @onUserSelected="addUserToFollowUp"
          @onUserUnselected="excludeUserFromFollowUp"
          :selectedMessage="$t('misc.attendees')"
        />
      </v-flex> -->

    <!-- REMOVE FOLLOWUP -->
    <!-- <v-flex class="pa-2 d-flex justify-end align-center" outlined tile>
        <v-tooltip left color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="mx-3"
              @click="deleteFollowup()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("misc.remove") }}</span>
        </v-tooltip>
      </v-flex>
    </v-card> -->
    <!-- BODY Feed events -->
    <v-dialog
      v-model="showFileViewer"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="1280"
    >
      <fileViewerColumn
        :showFileViewerCol="true"
        :focusFileViewerCol="true"
        :fileToShow="fileToShow"
        :filesGroupedByCard="filesGroupedByCard"
        v-if="filesGroupedByCard.length && fileToShow"
        @close="close"
      />
    </v-dialog>
  </div>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import { mapActions, mapGetters } from "vuex";
import cardsIndex from "@/components/cards/cards_index";
import searchAndSelectUsers from "@/components/users/searchAndSelectUsers";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import userItem from "@/components/users/userItem";
import SHOW_FOLLOWUP from "@/graphql/follow_ups/show.gql";
import CREATE_FOLLOWUP_GROUP from "@/graphql/follow_up_groups/create.gql";
import CREATE_FOLLOWUP_USER from "@/graphql/follow_up_users/create.gql";
import users from "@/mixins/users.js";
import topicToolbar from "./../topics/topicToolbar";
import fileViewerColumn from "../file_viewer/file_viewer_column.vue";
import loadingOverlay from "@/components/shared/loadingOverlay";

export default {
  name: "followup_show",
  mixins: [users],
  components: {
    cardsIndex,
    headerToolbar,
    searchAndSelectUsers,
    userAvatarGroup,
    topicToolbar,
    userItem,
    fileViewerColumn,
    loadingOverlay
  },
  props: {},
  data() {
    return {
      followUpId: "",
      followUp: {
        name: "",
        follow_up_users: [],
        cards: [],
      },
      fileToShow: null,
      showFileViewer: false,
      showShareMenu: false,
      loading: true
    };
  },
  computed: {
    ...mapGetters(["followUpCards"]),
    cards() {
      return this.followUpCards(this.followUpId).sort(this.sortByCreatedAt);
    },
    followUpName() {
      return this.followUp.name;
    },
    followUpUsers() {
      return this.followUp.follow_up_users.map((e) => e.user);
    },
    followUpUserIds() {
      return this.followUp.follow_up_users.map((e) => e.user && e.user.id);
    },
    filesGroupedByCard() {
      let _cards = this.cards.map((t) => {
        return {
          card_id: t.id,
          card_name: t.title ? t.title : t._title,
          files: t.files,
          updated_at: t.updated_at,
        };
      });

      //filter out those who don't have any feed events inside.
      _cards = _cards.filter((t) => t.files.length > 0);

      // if (this.currentTopic && this.showOnlyCurrentTopicItems)
      //   return _cards.filter((t) => t.card_id == this.currentTopic.id);

      return _cards.sort(this.sortByCreatedAt);
    },
  },
  methods: {
    ...mapActions([
      "openDestroyDialog",
      "addFollowUpCards",
      "cleanCards",
      "connectToFollowUpChannel",
      "disconnectFromFollowUpChannel",
      "setSelectedOrganisationId"
    ]),
    close() {
      this.fileToShow = null;
    },
    sortByCreatedAt(a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    },
    goToFollowUpsIndex() {
      this.$router.push({ name: "followups_index" });
    },
    followUpUserIdByUserId(userId) {
      return this.followUp.follow_up_users.find((e) => e.user && e.user.id === userId).id;
    },
    addUserToFollowUp(user) {
      this.$apollo
        .mutate({
          mutation: CREATE_FOLLOWUP_USER,
          variables: {
            user_id: user.id,
            follow_up_id: this.followUpId,
          },
        })
        .then(({ data }) => {
          this.getFollowUp();
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    excludeUserFromFollowUp(user) {
      this.openDestroyDialog({
        id: this.followUpUserIdByUserId(user.id),
        model: "follow_up_user",
        onDestroy: this.getFollowUp,
        title: this.$t("followUps.removeMemberAlertTitle"),
        message: this.$t("followUps.removeMemberAlertMessage")
          .replace("<followup_name>", this.followUp.name)
          .replace("<user_name>", this.userName(user)),
      });
    },
    addGroupToFollowUp(group) {
      this.$apollo
        .mutate({
          mutation: CREATE_FOLLOWUP_GROUP,
          variables: {
            user_id: group.id,
            follow_up_id: this.followUpId,
          },
        })
        .then(({ data }) => {
          this.getFollowUp();
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    getFollowUp() {
      this.loading = true;
      this.$apollo
        .query({
          query: SHOW_FOLLOWUP,
          variables: { id: this.followUpId },
        })
        .then(({ data }) => {
          this.setSelectedOrganisationId(data.follow_up.organisation_id);
          this.addFollowUpCards({
            follow_up_id: this.followUpId,
            cards: data.follow_up.cards,
          });
          this.followUp = data.follow_up;
          document.title = data.follow_up.name;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$router.push({ name: "page_not_found" });
        });
    },
    showFile(file) {
      this.fileToShow = file;
      console.log("Propagation on 3lvls working !!");
    },
  },
  created() {
    this.followUpId = this.$route.params.id;
    document.title = this.$t("followUps.loadingFollowup");
    this.getFollowUp();
    this.connectToFollowUpChannel(this.followUpId);
  },
  beforeDestroy() {
    this.cleanCards();
    this.disconnectFromFollowUpChannel(this.followUpId);
  },
  watch: {
    fileToShow() {
      if (!this.fileToShow) this.showFileViewer = false;
      else this.showFileViewer = true;
    },
    showFileViewer() {
      if (!this.showFileViewer) this.fileToShow = null;
    },
  },
};
</script>

<style>
.theme--light.v-application {
  background-color: var(--v-light_grey) !important;
}
</style>
