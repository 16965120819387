<template>
  <!-- Details popup -->
  <v-menu
    v-model="showFollowUpNextMeeting"
    :close-on-content-click="false"
    offset-y
    max-height="520"
    style="background-color: white"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        active-class="active_button "
        class="mx-2 "
        small
        rounded
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="pr-2" small color="button_blue"> mdi-calendar </v-icon>
        {{ $t("followUps.nextMeetings") }}
      </v-btn>
    </template>

    <v-card width="320" color="white">
      <v-list dense class="pa-1">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("followUps.nextMeetings") }}:
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list nav dense class="pa-2 pt-0">
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(meeting, i) in meetings"
            :key="i"
            :to="{ name: 'meeting_show', params: { id: meeting.id } }"
            target="_blank"
          >
            <v-list-item-icon class="pt-1 mr-2">
              <v-icon small>mdi-calendar</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="meeting.title"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="caption">
              {{ meeting.starts_at | friendlyDateHour }}
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
        <v-alert
          type="info"
          elevation="0"
          class="mx-2 mt-2 mb-2 caption"
          color="purple_alert"
          v-if="meetings.length == 0"
        >
          <span class="caption purple_alert_text--text">
            {{ $t("followUps.noMeetingsPlanned") }}
          </span>

          <template slot="prepend">
            <v-icon class="mr-4" color="purple_alert_text"
              >mdi-information</v-icon
            >
          </template>
        </v-alert>
      </v-list>

      <!-- <v-timeline dense class="pt-2">
        <v-timeline-item
          v-for="meeting in meetings"
          color="light_grey"
          small
          :key="meeting.id"
          @click=""
          class="pb-2"
        >
          <template v-slot:icon>
            <v-icon small>mdi-calendar</v-icon>
          </template>
          <v-list dense class="py-0">
            <v-list-item
              :to="{ name: 'meeting_show', params: { id: meeting.id } }"
              target="_blank"
              dense
            >
              <v-list-item-content class="py-0">
                <v-list-item-title v-text="meeting.title"></v-list-item-title>

                <v-list-item-subtitle>
                  {{
                    meeting.starts_at | friendlyDateHour
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-icon color="grey lighten-1"> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-timeline-item>
      </v-timeline> -->
    </v-card>
  </v-menu>
</template>

<script>
import date from "@/mixins/date";

export default {
  name: "followupNextMeetings",
  mixins: [date],
  components: {},
  props: {
    followUp: {
      type: Object,
    },
  },
  data() {
    return {
      showFollowUpNextMeeting: false,
    };
  },
  computed: {
    meetings() {
      var _meetings = [];
      if (this.followUp && this.followUp.topics && this.followUp.topics.length)
        _meetings = this.followUp.topics.map((fut) =>
          this.meetingFromTopic(fut)
        );

      return _meetings
        .sort(function (a, b) {
          return new Date(b.starts_at) - new Date(a.starts_at);
        })
        .reverse();
    },
    nextMeeting() {
      // if (
      //   this.followUp &&
      //   this.followUp.topics &&
      //   this.followUp.topics.length
      // ) {
      //   let topics_meetings = this.followUp.topics.map((fut) =>
      //     this.meetingFromTopic(fut)
      //   );
      //   console.log("topics meetings", topics_meetings);
      //   let meetingsDate = topics_meetings.map((md) => {
      //     return {
      //       id: md.id,
      //       time: new Date(md.starts_at).getTime(),
      //       name: md.title,
      //     };
      //   });
      //   console.log("meetingDate");
      //   const now = new Date().getTime();
      //   let closest = Infinity;
      //   meetingsDate.forEach(function (d) {
      //     const date = d.time;
      //     if (date >= now && (date < closest.time || date < closest)) {
      //       closest = d;
      //     }
      //   });
      //   console.log(closest.name);
      //   return closest;
      // }
      // return false;
    },
  },
  methods: {
    // goToMeeting(id) {
    //   this.$router.push({
    //     name: "meeting_show",
    //     params: { id: id },
    //   });
    // },
    meetingFromTopic(topic) {
      return {
        id: topic.meeting_id,
        title: topic.meeting_title,
        starts_at: topic.meeting_starts_at,
      };
    },
  },
  created() {},
  beforeDestroy() {},
};
</script>

<style>
</style>

<style lang="scss">
</style>
