<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    max-width="312"
    width="312"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        block
        depressed
        small
        color="light_grey"
        class="justify-start dark_grey--text"
        id="tags_button"
      >
        <v-icon small left>mdi-tag-outline</v-icon>
        {{ $t("cards.tags") }}
      </v-btn>
    </template>

    <v-card width="312">
      <v-card-title class="py-2">
        <v-btn
          small
          icon
          v-if="menuState != 'select'"
          @click="menuState = 'select'"
          class="mr-4"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="body-2">{{ menuTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="showMenu = false" small depressed icon
          ><v-icon small>mdi-close</v-icon></v-btn
        >
      </v-card-title>

      <v-divider></v-divider>

      <!-- SELECT A LABEL -->
      <v-sheet v-if="menuState == 'select'" class="pa-4">
        <v-text-field
          v-model="search"
          :placeholder="$t('cards.searchTags')"
          outlined
          rounded
          solo
          flat
          autofocus
          dense
          class="denseTextField mb-4"
          prepend-inner-icon="mdi-magnify"
          hide-details
          clearable
        ></v-text-field>

        <v-subheader class="denseSubheader px-0 mt-4 mb-2">
          {{ $t("cards.tags") }}:
        </v-subheader>

        <!-- <template v-if="loading"> loading </template>
        <template v-else>
          <template v-if="tags.length === 0">
            No tags found
          </template>
          <template v-else>
            <v-row v-for="tag in tags" :key="tag.id" :color="tag.color">
              {{ tag.name }}
            </v-row>
          </template>
        </template> -->
        <div style="max-height: 400px; overflow: auto">
          <v-row
            wrap
            no-gutters
            v-for="tag in sortedFilteredTags"
            :key="tag.id + tag.loading"
            class="mb-2"
          >
            <v-col cols="10" class="pa-0">
              <v-btn
                class="
                  justify-start
                  white--text
                  font-weight-medium
                  body-4
                  text-truncate
                  tagButton
                  justify-space-between
                  pl-0
                "
                depressed
                small
                block
                @click="toggleTag(tag)"
                :key="tag.id + tag.refresh"
                :color="tag.color"
              >
                <div
                  class="selectedButtonTagBorder"
                  :class="{
                    invisibleElement: !isSelected(tag),
                  }"
                ></div>
                <span class="button_tag_name">{{ tag.name }}</span>
                <v-spacer></v-spacer>
                <v-icon v-if="isSelected(tag)" color="white" small
                  >mdi-check</v-icon
                >
              </v-btn>
            </v-col>

            <v-col cols="2" class="pa-0 text-center">
              <v-btn class="v-btn-not-rounded" icon small @click="editTag(tag)"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </div>
        <v-btn
          small
          block
          color="button_blue"
          class="mt-4"
          dark
          depressed
          @click="newTag()"
        >
          <v-icon color="white" small left>mdi-tag-plus</v-icon>
          {{ $t("cards.createNewTag") }}
        </v-btn>
      </v-sheet>

      <!-- CREATE A LABEL -->
      <v-sheet v-if="menuState == 'create'" class="pa-4">
        <v-subheader class="denseSubheader px-0 my-2">
          {{ $t("cards.tagName") }}
        </v-subheader>

        <v-text-field
          v-model="newTagName"
          autofocus
          placeholder=""
          solo
          flat
          filled
          dense
          autocomplete="off"
          hide-details
        />

        <v-subheader class="denseSubheader px-0 my-2 mt-6">
          {{ $t("cards.tagColor") }}
        </v-subheader>

        <v-btn
          v-for="color in availableColors"
          :key="color"
          :color="color"
          class="mr-2 mt-2 pa-0 tagColorButton"
          depressed
          small
          @click="newTagColor = color"
        >
          <v-icon small color="white" v-if="newTagColor == color">
            mdi-check-bold
          </v-icon>
        </v-btn>
      </v-sheet>

      <!-- Update A LABEL -->
      <v-sheet v-if="menuState == 'update'" class="pa-4">
        <v-subheader class="denseSubheader px-0 my-2">
          {{ $t("cards.tagName") }}
        </v-subheader>

        <v-text-field
          v-model="editedTag.name"
          autofocus
          placeholder=""
          solo
          flat
          filled
          dense
          autocomplete="off"
          hide-details
        />

        <v-subheader class="denseSubheader px-0 my-2 mt-6">
          {{ $t("cards.tagColor") }}
        </v-subheader>

        <v-btn
          v-for="color in availableColors"
          :key="color"
          :color="color"
          class="mr-2 mt-2 pa-0 tagColorButton"
          depressed
          small
          @click="setEditedTagColor(color)"
        >
          <v-icon
            :key="color + refreshColor"
            small
            color="white"
            v-if="editedTag.color == color"
          >
            mdi-check-bold
          </v-icon>
        </v-btn>
      </v-sheet>

      <!-- <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="message" color="purple"></v-switch>
          </v-list-item-action>
          <v-list-item-title>Enable messages</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="hints" color="purple"></v-switch>
          </v-list-item-action>
          <v-list-item-title>Enable hints</v-list-item-title>
        </v-list-item>
      </v-list> -->

      <v-card-actions v-if="menuState != 'select'" class="px-4">
        <v-btn
          v-if="menuState == 'update'"
          color="error"
          class="white--text"
          depressed
          small
          :disabled="loadingAction"
          @click="destroyTag"
        >
          {{ $t("cards.tagDelete") }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          v-if="menuState == 'create'"
          color="button_blue"
          class="white--text"
          depressed
          small
          :disabled="loadingAction"
          @click="createTag()"
        >
          {{ $t("cards.tagCreate") }}
        </v-btn>

        <v-btn
          v-if="menuState == 'update'"
          color="button_blue"
          class="white--text"
          depressed
          small
          :disabled="loadingAction"
          @click="updateTag()"
        >
          {{ $t("cards.tagUpdate") }}
        </v-btn>

        <!-- <v-btn small text @click="showMenu = false"> Cancel </v-btn>
        <v-btn small color="primary" text @click="showMenu = false"> Save </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import * as api from "@/services/no-meetings.api";
import CREATE_CARD_TAG from "@/graphql/card_tags/create.gql";
import CREATE_TAG from "@/graphql/tags/create.gql";
import UPDATE_TAG from "@/graphql/tags/update.gql";
import TAGS_INDEX from "@/graphql/tags/index.gql";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "card_tags_menu",
  mixins: [],
  components: {},
  props: {
    cardTags: {
      type: Array,
      required: true,
    },
    cardId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      refreshColor: 0,
      tags: [],
      showMenu: false,
      menuState: "select",
      search: "",
      loading: true,
      loadingAction: false,
      availableColors: [
        "tag_color_1",
        "tag_color_2",
        "tag_color_3",
        "tag_color_4",
        "tag_color_5",
        "tag_color_6",
        "tag_color_7",
        "tag_color_8",
        "tag_color_9",
        "tag_color_10",
      ],
      newTagName: "",
      newTagColor: "tag_color_1",
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId"]),
    menuTitle() {
      switch (this.menuState) {
        case "select":
          return this.$t("cards.tags");
        case "create":
          return this.$t("cards.createTag");
        case "update":
          return this.$t("cards.editTag");
        default:
          return "";
      }
    },
    filter() {
      return `organisation_id == '${this.selectedOrganisationId}'`;
    },
    sortedFilteredTags() {
      let ret = this.tags;
      if (this.search)
        ret = this.tags.filter(
          (e) => e.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        );

      return ret.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    ...mapActions(["openDestroyDialog"]),
    setEditedTagColor(color) {
      this.editedTag.color = color;
      this.refreshColor++;
    },
    newTag() {
      this.menuState = "create";
      this.newTagName = "";
      this.newTagColor = "tag_color_1";
    },
    editTag(tag) {
      this.editedTag = {
        id: tag.id,
        color: tag.color,
        name: tag.name,
      };
      this.menuState = "update";
    },
    reset() {
      this.menuState = "select";
      this.newTagName = "";
      this.newTagColor = "tag_color_1";
      this.search = "";
    },
    getTags() {
      this.loading = true;
      this.$apollo
        .query({
          query: TAGS_INDEX,
          variables: { filter: this.filter },
        })
        .then(({ data }) => {
          this.loading = false;
          this.tags = data.tags;
        })
        .catch((e) => {
          this.loading = false;
          this.sendError(error);
          console.log(error);
        });
    },
    createTag() {
      this.loadingAction = true;
      this.$apollo
        .mutate({
          mutation: CREATE_TAG,
          variables: {
            organisation_id: this.selectedOrganisationId,
            name: this.newTagName,
            color: this.newTagColor,
          },
        })
        .then(({ data }) => {
          this.loadingAction = false;
          this.$amplitude
            .getInstance()
            .logEvent("Create card label", { name: this.newTagName, id: data.create_tag.id });

          this.tags.push({
            id: data.create_tag.id,
            name: this.newTagName,
            color: this.newTagColor,
          });
          this.menuState = "select";
          this.reset();
        })
        .catch((error) => {
          this.loadingAction = false;
          this.sendError(error);
          console.log(error);
        });
    },
    updateTag() {
      this.loadingAction = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_TAG,
          variables: this.editedTag,
        })
        .then(({ data }) => {
          this.loadingAction = false;
          this.tags = this.tags.filter((e) => e.id !== this.editedTag.id);
          this.tags.push({
            id: data.update_tag.id,
            name: this.editedTag.name,
            color: this.editedTag.color,
          });
          this.menuState = "select";
          this.reset();
        })
        .catch((error) => {
          this.loadingAction = false;
          this.sendError(error);
          console.log(error);
        });
    },
    destroyTag() {
      this.openDestroyDialog({
        id: this.editedTag.id,
        model: "tag",
        onDestroy: () => {
          this.getTags();
        },
        title: this.$t("cards.removeTagAlertTitle"),
        message: this.$t("cards.removeTagAlertMessage").replace(
          "<tag_name>",
          this.editedTag.name
        ),
      });
    },
    createTagCard(tag) {
      if (tag.loading) return;

      tag.loading = true;

      if (!this.cardId) return;
      this.$apollo
        .mutate({
          mutation: CREATE_CARD_TAG,
          variables: {
            card_id: this.cardId,
            tag_id: tag.id,
          },
        })
        .then(({ data }) => {
          tag.loading = false;
        })
        .catch((error) => {
          tag.loading = false;
          this.sendError(error);
          console.log(error);
        });
    },
    async destroyCardTag(tag) {
      if (tag.loading) return;

      let id = this.cardTags.find((e) => e.tag.id === tag.id).id;
      if (!id) return;

      try {
        tag.loading = true;
        const { data } = await api.destroy(id, "card_tag");
        tag.loading = false;
      } catch (err) {
        tag.loading = false;
        this.sendError(err);
        console.error(err);
      }
    },
    isSelected(tag) {
      return this.cardTags.find((e) => e.tag.id == tag.id);
    },
    toggleTag(tag) {
      if (this.isSelected(tag)) {
        // REMOVE TAG FROM CARD
        console.log("toggle remove");
        this.destroyCardTag(tag);
      } else {
        // ADD TAG TO CARD
        console.log("toggle add");
        this.createTagCard(tag);
      }
    },
  },
  created() {},
  watch: {
    showMenu() {
      if (!this.showMenu) this.reset();
      if (this.showMenu) this.getTags();
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
