<template>
  <div>
    <!-- {{ value }} {{ valueInt }} -->
    <div class="task-priority-wrapper">
      <span class="grey--text pl-1 caption" v-if="hoverIndex != -1" :style="'position: absolute; top: -' + captionYoffset + 'px;'">
        {{$t("tasks.priorities."+priorities[hoverIndex])}}
      </span>
      <v-btn
        @mouseenter="setHoverIndex(i)"
        @mouseleave="setHoverIndex(-1)"
        v-for="(priority, i) in priorities"
        width="14"
        height="14"
        icon
        :key="priority"
        :class="{

          'task-priority-box-neutral': true,
          'task-priority-box': true,
          [colors[valueInt]]: hoverIndex == -1 && valueInt >= i,
          [colors[hoverIndex]]: hoverIndex >= i,
          'task-priority-hovered': hoverIndex >= i,
        }"
        @click="selectedValue(i)"
      ></v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "taskPriority",
  mixins: [],
  components: {},
  props: {
    value: {
      type: String,
      default: "low",
      required: false,
    },
    readonly: {
      type: Boolean,
      default: true,
      required: false
    },
    captionYoffset: {
      type: Number,
      default: 2,
      required: false
    }
  },
  data() {
    return {
      priorities: ["low", "medium", "high", "critical"],
      colors: ["button_blue lighten-3", "button_blue lighten-2", "button_blue lighten-1", "button_blue"],
      hoverIndex: -1,
    };
  },
  computed: {
    valueInt() {
      return this.priorities.indexOf(this.value);
    },
  },
  methods: {
    setHoverIndex(index) {
      if (this.readonly) return;
      this.hoverIndex = index;
    },
    selectedValue(index) {
      if (this.readonly) return;
      if (this.value != this.priorities[index]) { //avoid emit when value is the same
        console.log("selected value: ", this.priorities[index]);
        this.$emit("selectedValue", this.priorities[index]);
      }
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style scoped>
</style>


