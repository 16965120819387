export default {
  methods: {
    userName(user) {
      if (user.first_name || user.last_name)
        return [user.first_name, user.last_name].join(" ");
      else
        return user.email;
    },
    usersAsStringList(users, delimiter) {
      return users
        .map((user) => this.userName(user))
        .join(delimiter)
    }
  }
};
