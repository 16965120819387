var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 cardIndexContainer"},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":"","wrap":""}},[(_vm.cards.length || _vm.archivedCards.length)?_c('v-col',{staticClass:"flex-grow-0 flex-shrink-1 hidden-md-and-down",staticStyle:{"min-width":"300px","height":"100%"},attrs:{"cols":"1"}},[_c('div',{staticClass:"spacerCol"}),_c('v-navigation-drawer',{staticStyle:{"height":"calc(100% - 96px)"},attrs:{"permanent":"","floating":"","color":"transparent","width":"300"}},[_c('v-list',{staticClass:"pt-2 px-5 pb-0",staticStyle:{"overflow-y":"auto"},attrs:{"dense":"","nav":"","color":"white"}},[_c('v-list-item',{staticStyle:{"background":"white"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("misc.cards"))+" ("+_vm._s(_vm.cards.length)+")")])],1)],1)],1),_c('v-list',{staticClass:"py-0 px-3 pb-4",staticStyle:{"overflow-y":"auto"},attrs:{"dense":"","nav":"","color":"white"}},_vm._l((_vm.cards),function(card){return _c('v-list-item',{key:'smry_' + card.id,staticStyle:{"min-height":"34px"},attrs:{"link":""},on:{"click":function($event){return _vm.scrollToCard(card.id)}}},[(
                _vm.currentMeetingId &&
                card.last_update_at_meeting_id === _vm.currentMeetingId
              )?_c('v-icon',{staticStyle:{"position":"absolute","left":"-8px"},attrs:{"size":"16","color":"success"}},[_vm._v(" mdi-circle-medium ")]):_vm._e(),_c('v-list-item-content',{staticClass:"pl-2"},[_c('v-list-item-subtitle',{class:{
                  'font-weight-bold':
                    _vm.currentMeetingId &&
                    card.last_update_at_meeting_id === _vm.currentMeetingId,
                }},[_vm._v(" "+_vm._s(card.title ? card.title : _vm.$t("cards.noTitle"))+" ")])],1)],1)}),1),(_vm.archivedCards.length)?_c('v-list',{staticClass:"py-0 px-3 pb-4",staticStyle:{"overflow-y":"auto"},attrs:{"dense":"","nav":"","color":"white"}},[_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"px-2"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("cards.archivedItems"))+" ("+_vm._s(_vm.archivedCards.length)+")")])],1)]},proxy:true}],null,false,256326099)},_vm._l((_vm.archivedCards),function(card){return _c('v-list-item',{key:'smry_' + card.id,staticClass:"px-2",staticStyle:{"min-height":"34px"},attrs:{"link":""},on:{"click":function($event){return _vm.scrollToArchivedCard(card.id)}}},[(
                  _vm.currentMeetingId &&
                  card.last_update_at_meeting_id === _vm.currentMeetingId
                )?_c('v-icon',{staticStyle:{"position":"absolute","left":"-4px","z-index":"10"},attrs:{"size":"16","color":"success"}},[_vm._v(" mdi-circle-medium ")]):_vm._e(),_c('v-list-item-content',{staticClass:"pl-2"},[_c('v-list-item-subtitle',{class:{
                    'font-weight-bold':
                      _vm.currentMeetingId &&
                      card.last_update_at_meeting_id === _vm.currentMeetingId,
                  }},[_vm._v(" "+_vm._s(card.title ? card.title : _vm.$t("cards.noTitle"))+" ")])],1)],1)}),1)],1):_vm._e()],1)],1):_vm._e(),_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0",staticStyle:{"min-width":"100px","max-width":"100%","height":"100%"},attrs:{"cols":"1"}},[_c('div',{staticClass:"text-center spacerCol align-center d-flex justify-center"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"button_blue","rounded":"","small":"","dark":"","depressed":""},on:{"click":_vm.openNewCard}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("misc.newCard"))+" ")],1)],1),(_vm.cards.length == 0 && _vm.archivedCards.length == 0)?_c('v-row',{staticClass:"justify-center align-center fill-height",staticStyle:{"max-height":"600px"},attrs:{"wrap":"","no-gutters":""}},[_c('v-col',{staticClass:"justify-center align-center text-center",attrs:{"cols":"12"}},[_c('img',{staticClass:"centeredTemplateSvgs",staticStyle:{"max-width":"100%"},attrs:{"src":"/images/svg_images/emptynotes.svg"}}),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("followUps." + (_vm.followUpId ? "followUpEmpty" : "topicEmpty")))+" ")]),_c('span',{staticClass:"body-3"},[_vm._v(" "+_vm._s(_vm.$t("textEditor.startByAdding"))+" ")])])],1):_c('div',{staticClass:"mx-4",staticStyle:{"overflow-y":"auto","height":"calc(100% - 68px)","padding-bottom":"10vh"},attrs:{"id":'card_list_' + _vm.followUpId}},[_vm._l((_vm.cards),function(card){return _c('div',{key:'card_' + card.id},[_c('cardWrapper',{attrs:{"recentlyUpdated":!!(
                _vm.currentMeetingId &&
                card.last_update_at_meeting_id === _vm.currentMeetingId
              ),"card":card,"id":'div_' + card.id,"currentMeetingId":_vm.currentMeetingId},on:{"seeHistoryUpdate":_vm.seeHistoryUpdate,"showFile":_vm.showFile,"scrollToCard":_vm.scrollToCard,"openCardDialog":function($event){_vm.cardDialog = true}}})],1)}),(_vm.archivedCards.length)?_c('v-expansion-panels',{staticClass:"archivedCardIndex pt-2",attrs:{"flat":"","accordion":""},model:{value:(_vm.displayArchived),callback:function ($$v) {_vm.displayArchived=$$v},expression:"displayArchived"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pt-0 px-3"},[_c('span',{staticClass:"font-weight-medium"},[_c('v-icon',{staticStyle:{"top":"-1px"},attrs:{"small":"","left":""}},[_vm._v("mdi-archive-outline")]),_vm._v(_vm._s(_vm.$t("cards.archivedItems"))+" ("+_vm._s(_vm.archivedCards.length)+")")],1)]),_c('v-expansion-panel-content',{attrs:{"id":"div_archived_panel"}},_vm._l((_vm.archivedCards),function(card){return _c('div',{key:'card_' + card.id},[_c('cardWrapper',{attrs:{"recentlyUpdated":!!(
                      _vm.currentMeetingId &&
                      card.last_update_at_meeting_id === _vm.currentMeetingId
                    ),"card":card,"id":'div_' + card.id,"currentMeetingId":_vm.currentMeetingId},on:{"seeHistoryUpdate":_vm.seeHistoryUpdate,"showFile":_vm.showFile,"scrollToCard":_vm.scrollToCard,"openCardDialog":function($event){_vm.cardDialog = true}}})],1)}),0)],1)],1):_vm._e()],2)],1)],1),_c('v-dialog',{attrs:{"max-width":"940","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.xs},model:{value:(_vm.cardDialog),callback:function ($$v) {_vm.cardDialog=$$v},expression:"cardDialog"}},[_c('cardEditor',{attrs:{"card":_vm.newCard,"currentMeetingId":_vm.currentMeetingId},on:{"closeDialog":function($event){_vm.cardDialog = false},"deleteCard":_vm.destroyCard}})],1),_c('cardHistory',{attrs:{"cardId":_vm.showCardHistoryId},on:{"seeHistoryUpdate":_vm.seeHistoryUpdate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }