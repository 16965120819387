var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{class:{ meetingMargin: _vm.isInMeeting },attrs:{"flat":"","color":"white","dense":""}},[_c('v-toolbar-title',{staticClass:"mr-8 body-1 almost_black--text "},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.subject))]),(_vm.followUp && _vm.followUp.project_id)?_c('v-chip',{staticClass:"light_grey ml-4",attrs:{"small":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.followUp.project_color,"size":"13"}},[_vm._v("mdi-folder")]),_vm._v(" "+_vm._s(_vm.followUp.project_name)+" ")],1):_vm._e()],1),_c('v-spacer'),(_vm.followUp)?_c('followupNextMeetings',{attrs:{"followUp":_vm.followUp}}):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","close-on-click":!_vm.editDetails},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"active-class":"active_button","small":"","rounded":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"small":"","color":"button_blue"}},[_vm._v(" mdi-card-text-outline ")]),_vm._v(" "+_vm._s(_vm.$t("meetings.details"))+" ")],1)]}}]),model:{value:(_vm.showDetailsPopUp),callback:function ($$v) {_vm.showDetailsPopUp=$$v},expression:"showDetailsPopUp"}},[_c('v-card',{attrs:{"width":"460"}},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("meetings.details"))+": ")])],1),_c('v-list-item-action',[(false)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.editDetails = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1)],1)],1),_c('v-card-text',{staticClass:"pt-0"},[(_vm.editDetails)?_c('v-textarea',{attrs:{"dense":"","filled":"","label":_vm.$t('misc.description'),"flat":""},model:{value:(_vm.tempDescription),callback:function ($$v) {_vm.tempDescription=$$v},expression:"tempDescription"}}):[_c('b',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("misc.description")))]),_c('p',{domProps:{"textContent":_vm._s(
                _vm.description
                  ? _vm.description
                  : _vm.$t('meetings.topicDescriptionNotDefined')
              )}})],_c('v-row',{attrs:{"wrap":"","no-gutters":""}},[_c('b',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("misc.owner")))]),_c('v-col',{attrs:{"cols":"12"}},[(_vm.owner)?_c('div',[_c('userAvatar',{attrs:{"size":16,"show_name_in_tooltip":false,"first_name":_vm.owner.first_name,"last_name":_vm.owner.last_name,"avatar_url":_vm.owner.avatar_url,"email":_vm.owner.email}}),_c('span',{staticClass:"caption pl-2"},[_vm._v(_vm._s(_vm.owner.first_name + " " + _vm.owner.last_name))])],1):_vm._e()])],1)],2)],1)],1),(!_vm.isInMeeting)?[_vm._t("followupAddUsers")]:_vm._e()],2),(_vm.divider)?_c('v-divider'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }