 <template>
  <v-dialog :value="display" persistent width="500">
    <v-card>
      <v-card-text class="text-xs-center d-flex justify-center pa-4">
        <v-row wrap no-gutters>
          <v-col class="pt-2 text-xs-center d-flex justify-center" cols="12"
            ><v-progress-circular color="button_blue" indeterminate size="48" width="4"></v-progress-circular></v-col>
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-title class="pa-0 pt-4">
              {{ message }}
            </v-card-title></v-col
          >
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-subtitle class="pa-0">
              {{ $t("misc.pleaseWait") }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: "loadingOverlay",
  props: {
    message: {
      type: String,
      default: "Loading data. Please wait...",
      required: false,
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
