<template>
  <v-dialog
    v-model="dialog"
    max-width="480"
    transition="dialog-top-transition"
    persistent
  >
    <template v-slot:default>
      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          <span> {{ $t("textEditor.insertUrl") }}</span>
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-5">
           <!-- <v-text-field
            v-model="text"
            v-if="editedText"
            placeholder=""
            filled
            dense
            solo
            flat
            autocomplete="off"
          /> -->

          <v-text-field
            v-model="url"
            autofocus
            placeholder="http://exemple.com"
            filled
            dense
            solo
            flat
            @keyup.enter="insertUrl()"
            autocomplete="off"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="closeDialog()" color="almost_black">
            <v-icon class="pr-1" small>mdi-close</v-icon>
            {{ $t("misc.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            class="white--text"
            depressed
            @click="insertUrl()"
          >
            Insert text
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "editorLinkDialog",
  mixins: [],
  components: {},
  props: {
    editedUrl: {
      type: String,
      default: "",
      required: false,
    },
    editedText: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      dialog: true,
      url: "",
      text: "",
    };
  },
  computed: {},
  methods: {
    insertUrl() {
      this.$emit("insertUrl", {url: this.url, text: this.text});
    },
    closeDialog() {
      this.$emit("close");
    },
  },
  created() {
    if (this.editedUrl) this.url = this.editedUrl;
    if (this.editedText) this.text = this.editedText;
  },
};
</script>

