 <template>
  <v-card
    flat
    color="white"
    :tile="fullscreen"
    
    :class="{ fullscreenFileViewer: fullscreen, 'rounded-sm': !fullscreen }"
  >
    <v-toolbar
      dense
      :dark="fullscreen"
      flat
      :color="fullscreen ? 'main_blue' : 'transparent'"
      :class="{ tile: fullscreen }"
    >
      <div
        :style="
          mobileVersion
            ? 'max-width: 200px; width: 200px'
            : 'max-width: 260px; width: 260px'
        "
      >
        <v-autocomplete
          solo
          flat
          dense
          hide-details
          v-model="selectedFileId"
          :items="fileList"
          :item-text="getFilename"
          item-value="id"
          :placeholder="$t('fileViewer.selectFile')"
          background-color="transparent"
        >
          <template v-slot:item="data">
            <img
              height="16"
              :src="
                fileIconFromExtension(fileExtensionFromUrl(data.item.file_url))
              "
            />

            <v-list-item-subtitle class="pl-4">
              {{ getFilename(data.item) }}
            </v-list-item-subtitle>
          </template>

          <template v-slot:selection="data">
            <img
              class="mb-1"
              height="14"
              :src="
                fileIconFromExtension(fileExtensionFromUrl(data.item.file_url))
              "
            />

            <v-list-item-subtitle class="pl-2 font-weight-bold">
              {{ getFilename(data.item) }}
            </v-list-item-subtitle>
          </template>
        </v-autocomplete>
      </div>

      <v-spacer></v-spacer>
      <slot name="toolbar" />
      <div v-if="selectedFileId">
        <v-btn
          :icon="$vuetify.breakpoint.smAndDown"
          small
          text
          v-if="!mobileVersion && !$vuetify.breakpoint.smAndDown"
          @click="toggleFullscreen()"
        >
          <v-icon small>
            {{ fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}
          </v-icon>
          <span class="pl-1 hidden-sm-and-down">
            {{
              fullscreen
                ? $t("fileViewer.standardView")
                : $t("fileViewer.fullscreen")
            }}</span
          >
        </v-btn>

        <v-btn
          :icon="$vuetify.breakpoint.smAndDown"
          small
          text
          download
          target="_blank"
          :href="selectedFile.file_url"
        >
          <v-icon small>mdi-download</v-icon>
          <span class="pl-1 hidden-sm-and-down">
            {{ $t("fileViewer.download") }}</span
          >
        </v-btn>

        <v-btn :icon="$vuetify.breakpoint.smAndDown" small text @click="close()">
          <v-icon small>mdi-close</v-icon>
          <span class="pl-1 hidden-sm-and-down"> {{ $t("misc.close") }}</span>
        </v-btn>
      </div>
    </v-toolbar>
    <v-divider />
    <!-- <v-progress-linear
      indeterminate
      color="button_blue"
      :active="loadingQuery"
    ></v-progress-linear> -->
    <v-card-text
      class="pt-0 px-0 pb-0"
      id="items_scroll_area"
      :class="{
        fillHeight: fullscreen || $vuetify.breakpoint.smAndDown,
        modalHeight: !fullscreen && !$vuetify.breakpoint.smAndDown
      }"
    >
      <template
        v-if="fileViewerType(selectFileExtension) == 'OfficeWebAppsViewer'"
      >
        <iframe
          :src="
            fileViewersUrls(fileViewerType(selectFileExtension)).replace(
              '[FILE_URL]',
              selectedFile.file_url
            )
          "
          width="100%"
          height="100%"
          frameborder="0"
        >
        </iframe>
      </template>

      <template
        v-if="fileViewerType(selectFileExtension) == 'GoogleDocsViewer'"
      >
        <iframe
          :src="
            fileViewersUrls(fileViewerType(selectFileExtension)).replace(
              '[FILE_URL]',
              selectedFile.file_url
            )
          "
          width="100%"
          height="100%"
          frameborder="0"
        >
        </iframe>
      </template>

      <imageViewer
        v-if="fileViewerType(selectFileExtension) == 'LocalImageViewer'"
        :images="allImages"
        :selectedImageIndex="allImages.indexOf(selectedFile.file_url)"
        @viewedIndexChange="viewedIndexChange"
        :key="'img_viewer_' + fullscreen ? 'fs' : 'rg'"
        :fullscreen="fullscreen"
      />
      <!--
      </imageViewer> -->

      <!-- <viewer
      :images="fileList.map(fl => fl.file_url)"
      :options="{inline: true}"
      class="viewer"
      ref="viewer"
      @inited="inited"

    >
      <img
        v-for="im in fileList.map(fl => fl.file_url)"
        :src="im"
        :data-source="im"
        :key="im"
        class="image"
      >
    </viewer> -->
      <!-- <jsonViewer :value="files" />
      <jsonViewer :value="filesGroupedByCard" />
      <jsonViewer :value="fileList" /> -->
    </v-card-text>
  </v-card>
</template>


<script>
import files from "@/mixins/files.js";
import imageViewer from "@/components/file_viewer/imageViewer";

export default {
  name: "fileViewerWrapper",
  mixins: [files],
  components: { imageViewer },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    filesGroupedByCard: {
      type: Array,
      default: () => [],
    },
    openedFile: {
      type: Object,
      default: () => {},
    },
    mobileVersion: {
      type: Boolean,
      required: false,
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedFileId: "",
      
    };
  },
  computed: {

    selectedFile() {
      var sf = this.fileList.find((fl) => fl.id == this.selectedFileId);
      return sf;
    },
    selectFileExtension() {
      return this.selectedFile
        ? this.fileExtensionFromUrl(this.selectedFile.file_url)
        : "";
    },
    fileList() {
      // Return files array for simples array of file items.
      if (this.files.length) return this.files;

      // Return files and meeting headers for files that are splitted into different topics
      let ret = [];
      this.filesGroupedByCard.forEach((fbm) => {
        ret.push({ header: fbm.card_name });
        fbm.files.forEach((fi) => {
          ret.push(fi);
        });
      });
      return ret;
    },
    allImages() {
      // Return files array for simples array of file items.
      if (this.files.length)
        return this.files
          .filter(
            (f) =>
              this.fileViewerType(this.fileExtensionFromUrl(f.file_url)) ==
              "LocalImageViewer"
          )
          .map((fi) => fi.file_url);

      // Return files and meeting headers for files that are splitted into different topics
      let ret = [];
      this.filesGroupedByCard.forEach((fbm) => {
        fbm.files.forEach((fi) => {
          if (
            this.fileViewerType(this.fileExtensionFromUrl(fi.file_url)) ==
            "LocalImageViewer"
          )
            ret.push(fi.file_url);
        });
      });
      return ret;
    },
  },
  methods: {
    toggleFullscreen(e) {
      this.$emit("toggleFullscreen", !this.fullscreen)
    },
    viewedIndexChange(e) {
      this.selectedFileId = this.fileList.find(
        (fl) => fl.file_url == e.detail.image.currentSrc
      ).id;
    },
    close() {
      this.$emit("close");
    },
    getFilename(item) {
      if (item.header) return item.header;
      return this.fileNameFromUrl(item.file_url);
    },
  },
  mounted() {},
  created() {
    if (this.openedFile) this.selectedFileId = this.openedFile.id;
    if (this.mobileVersion) this.fullscreen = true;
  },
  watch: {},
  beforeDestroy() {},
};
</script>

<style>
.fullscreenFileViewer {
  position: fixed;
  transition: all 0.2s ease-in-out;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
   z-index: 10 !important; 
}

.fillHeight {
   
  height: calc(100vh - 48px);
}

.modalHeight {
  height: 80vh;
  
  
}
</style>
