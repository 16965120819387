<template>
  <v-tooltip top :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :text="!active"
        :color="color"
        depressed
        :disabled="disabled"
        @click="$emit('btnClic')"
        small
        class="rounded-sm editorButton editorButtonFocus"
      >
        <v-icon size="22" :color="iconColor">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span class="text-capitalize">{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "editorButton",
  props: {
    color: {
      type: String,
      default: "grey lighten-3",
    },
    icon: {
      type: String,
      default: "?",
    },
    active: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "#585858"
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
</style>