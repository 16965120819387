<template>
  <v-dialog v-model="dialog" max-width="480" transition="dialog-top-transition">
    <template v-slot:default>
      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          <span><v-icon left color="button_blue">mdi-history</v-icon>  History</span>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-5" v-if="!loading"> </v-card-text>
          <v-card-text v-else class="text-xs-center d-flex justify-center pa-8">
        <v-row wrap no-gutters>
          <v-col class="text-xs-center d-flex justify-center" cols="12"
            >
            <v-progress-circular color="button_blue" indeterminate size="48" width="4"></v-progress-circular>
            </v-col>
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-title class="pa-0 pt-4">
              Loading history...
            </v-card-title></v-col
          >
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-subtitle class="pa-0">
              {{ $t("misc.pleaseWait") }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false" color="almost_black">
            <v-icon class="pr-1" small>mdi-close</v-icon>
            {{ $t("misc.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import userAvatar from "../shared/userAvatar";

export default {
  name: "cardHistory",
  mixins: [],
  components: {
    userAvatar,
  },
  props: {
    cardId: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {},
  methods: {
    getCardHistory() {
      // Reset data and query history
      this.dialog = true;
      this.loading = true;

    },
  },
  created() {},
  watch: {
    cardId() {


        if (!this.cardId) {
        } else {
          this.getCardHistory();
        }

    },
    dialog() {
      if (!this.dialog) {
          console.log("dialog closed")
        this.dialog = false;
         this.loading = false;
        this.$emit("seeHistoryUpdate", "");
      }
    },
  },
};
</script>

