<template>
  <v-row no-gutters wrap align="center">
    <!-- assign -->
    <v-menu
      :close-on-content-click="false"
      :nudge-width="200"
      max-height="600px"
      max-width="400px"
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-btn
          x-small
          class="mx-2"
          rounded
          depressed
          color="light_grey dark_grey--text  "
          v-bind="attrs"
          v-on="{ ...menu }"
        >
          <v-icon
            x-small
            :class="{
              'pr-1': selectedUsers.length,
            }"
            >mdi-account</v-icon
          >

          <userAvatarGroup
            :limit="1"
            :users="selectedUsers"
            :size="14"
            :disabledMenu="true"
            :avatar_border="false"
          />
        </v-btn>
      </template>
      <v-card flat class="px-2">
        <v-subheader>{{ $t("textEditor.assignTask") }}:</v-subheader>

        <searchAndSelectUsers
          :selectedUsersIds="selectedUsers.map((e) => e.id)"
          :selectedMessage="$t('textEditor.assignedToTask')"
          @onUserSelected="onUserSelected"
          @onUserUnselected="onUserUnselected"
        />
        <v-list dense two-lines class="py-0">
          <userItem v-for="user in selectedUsers" :key="user.id" :user="user">
            <template v-slot:action>
              <v-btn icon @click="onUserUnselected(user)">
                <v-icon color="error" small>mdi-account-minus</v-icon>
              </v-btn>
            </template>
          </userItem>
        </v-list>
      </v-card>
    </v-menu>

    <!-- Due Date -->

    <v-menu
      v-model="showDateMenu"
      offset-y
      :ref="'menu_date'"
      :close-on-content-click="true"
      :return-value.sync="selectedDate"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <!-- <v-tooltip top >
          <template v-slot:activator="{ on: tooltip }"> -->
        <v-btn
          x-small
          rounded
          class="mx-2"
          depressed
          color="light_grey dark_grey--text "
          v-bind="attrs"
          v-on="{ ...menu }"
        >
          <v-icon
            x-small
            :class="{
              'pr-2': date,
            }"
            >mdi-calendar-check</v-icon
          >
          {{ date ? friendlyDate(date) : "" }}
        </v-btn>
        <!--  </template>

          <span> {{ $t("textEditor.dueDate") }}</span>
        </v-tooltip> -->
      </template>
      <v-date-picker
        v-model="selectedDate"
        no-title
        scrollable
        :min="todaysDate"
        @change="
          $refs['menu_date'].save(selectedDate);
          $emit('dateSelected', selectedDate);
        "
      >
      </v-date-picker>
    </v-menu>
  </v-row>
</template>

<script>
import date from "@/mixins/date";
import searchAndSelectUsers from "@/components/users/searchAndSelectUsers";
import userItem from "@/components/users/userItem";
import userAvatarGroup from "@/components/shared/userAvatarGroup";

export default {
  name: "dateAndUserPicker",
  components: { searchAndSelectUsers, userItem, userAvatarGroup },
  mixins: [date],
  props: {
    selectedUsers: {
      type: Array,
      required: true,
    },
    date: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      showDateMenu: false,
      selectedDate: null,
      todaysDate: new Date().toISOString(),
    };
  },
  methods: {
    onUserSelected(user) {
      this.$emit("onUserSelected", user.id);
    },
    onUserUnselected(user) {
      this.$emit("onUserUnselected", user.id);
    },
    initSelectedDate() {
      let tmp = this.date ? this.date : this.todaysDate;
      this.selectedDate = new Date(tmp).toISOString().substr(0, 10);
    },
  },
  watch: {
    date() {
      this.initSelectedDate();
    },
  },
  created() {
    this.initSelectedDate();
  },
};
</script>

<style scoped>
</style>
