import editorToolBar from "@/components/text_editor/editor_toolbar.vue";

import { Editor, EditorContent, VueNodeViewRenderer } from "@tiptap/vue-2";

// Tiptap additionnal extensions
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Document from "@tiptap/extension-document";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Gapcursor from "@tiptap/extension-gapcursor";

export default {
  components: {
    editorToolBar,
    EditorContent,
  },
  data() {
    return {
      editor: null,
      autoSaveTimeoutId: null
    }
  },
  computed: {},
  methods: {
    emptyContent() {
      return this.editor.isEmpty;
    },
    setContent(content) {
      this.editor.commands.setContent(content);
    },
    getContent(type) {
      // Type can be "JSON", "HTML", "TEXT"
      // TEXT will return similar to what inner html does, without the tags.
      switch (type) {
        case "JSON": return this.editor.getJSON();
        case "HTML": return this.editor.getHTML();
        case "TEXT": return this.editor.state.doc.textContent;
        default: return this.editor.getHTML();
      }
    },
    focusEditor() {
      console.log("focus editor");
      this.editor.commands.focus();
    },
    initEditor(content = "") {
      this.editor = new Editor({
        content: content,
        extensions: [
          TaskList,
          TaskItem,
          Document,
          Paragraph,
          Text,
          StarterKit,
          Underline,
          Highlight,
          Table.configure({
            resizable: false,
          }),
          Gapcursor,
          TableRow,
          TableHeader,
          TableCell,
          Link.configure({
            openOnClick: false,
            linkOnPaste: true
          }),
          Placeholder.configure({
            placeholder: this.$t("textEditor.writeYourNoteHere"),
          })
        ],
        onBlur: ({ editor, event }) => {
          console.log(event.relatedTarget)
          if (
            event.relatedTarget &&
            event.relatedTarget.classList.contains("editorButtonFocus")
          ) {
            this.focusEditor();
          }
        },
        onUpdate: () => {
           clearTimeout(this.autoSaveTimeoutId);
           this.autoSaveTimeoutId = setTimeout(() => {
             this.saveContent(this.getContent("HTML"));
           }, 1000);
        },
      });
    },
    saveContent(content) {
      console.log('save editor content mixin, override me plz!')
    }
  },
  mounted() {
  },
};
