<template>
  <div>
    <template v-if="editor && editor.isFocused ">
      <!-- Editor buttons  -->

      <editorButton
        icon="mdi-format-bold"
        @btnClic="editor.chain().focus().toggleBold().run()"
        :active="editor.isActive('bold')"
        tooltip="bold"
      />

      <editorButton
        icon="mdi-format-italic"
        @btnClic="editor.chain().focus().toggleItalic().run()"
        :active="editor.isActive('italic')"
        tooltip="italic"
      />

      <editorButton
        icon="mdi-format-underline"
        @btnClic="editor.chain().focus().toggleUnderline().run()"
        :active="editor.isActive('underline')"
        tooltip="underline"
      />

      <editorButton
        icon="mdi-format-color-highlight"
        @btnClic="editor.chain().focus().toggleHighlight().run()"
        :active="editor.isActive('highlight')"
        tooltip="highlight"
        color="#ffee58"
      />

      <v-divider vertical class="editorButtonSeparator" />

      <editorButton
        icon="mdi-format-list-bulleted"
        @btnClic="editor.chain().focus().toggleBulletList().run()"
        :active="editor.isActive('bulletList')"
        tooltip="bullet list"
      />

      <editorButton
        icon="mdi-format-list-numbered"
        @btnClic="editor.chain().focus().toggleOrderedList().run()"
        :active="editor.isActive('orderedList')"
        tooltip="ordered list"
      />

      <v-divider vertical class="editorButtonSeparator" />

      <editorButton
        icon="mdi-link-variant-plus"
        v-if="!editor.isActive('link')"
        @btnClic="insertUrlDialog()"
        :active="false"
        tooltip="Insert link"
      />

      <!-- Edit/remove link -->
      <template v-if="editor.isActive('link')">
        <editorButton
          icon="mdi-link-variant"
          @btnClic="editUrlDialog()"
          :active="true"
          tooltip="Edit link"
        />

        <editorButton
          icon="mdi-link-variant-off"
          @btnClic="removeUrl()"
          :active="true"
          tooltip="Remove link"
          color="error"
          iconColor="#ffffff"
        />
      </template>

      <v-divider vertical class="editorButtonSeparator" />

      <!-- <editorButton
        icon="mdi-table"
       
        @btnClic="editor.chain().focus().insertTable({ rows: 3, cols: 2, withHeaderRow: true }).run()"
        :active="false"
        tooltip="Insert Table"
      /> -->

      <!-- Add a table with grid like selection -->
      <v-menu right offset-x offset-y  class="editorButtonFocus">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                text
                color="grey lighten-3"
                depressed
                small
                class="rounded-sm editorButtonFocus"
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon size="22" color="#585858">mdi-table</v-icon>
              </v-btn>
            </template>
            <span>Insert Table</span>
          </v-tooltip>
        </template>

        <v-card class="pa-2 editorButtonFocus">
          <v-row
            v-for="(y, iy) in newTableGridHeight"
            wrap
            no-gutters
            :key="'c_' + iy + '_' + y"
            class="ma-0"
          >
            <v-col
              tabindex="0"
              :key="'r_' + x"
              v-for="(x, ix) in newTableGridWidth"
              :class="{
                editorButtonFocus: true,
                'pa-0': true,
                tableSelectorGridItemWrapper: true,
              }"
              @click="
                editor
                  .chain()
                  .focus()
                  .insertTable({ rows: y, cols: x, withHeaderRow: true })
                  .run()
              "
              @mouseover="setTableSelectionGridSize(x, y)"
            >
              <div
                :class="{
                  tableSelectorGridItem: true,
                  tableSelectorGridItemHighlighted:
                    x <= newTableWidthSelection && y <= newTableHeightSelection,
                }"
              ></div>
              <!-- {{ y * 10 + x }}@mouseover.native="focus" -->
            </v-col>
          </v-row>
          <v-card-actions
            class="text-center align-center justify-center caption editorButton"
          >
            <span
              >{{ newTableWidthSelection }}x{{ newTableHeightSelection }}</span
            >
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-card
        v-if="editor.isActive('table')"
        class="elevation-3"
        style="position: absolute; transform: translate(120px, -86px); z-index: 6"
      >
        <v-row wrap no-gutters class="pa-2">
        

          <editorButton
            :tooltip="$t('textEditor.addColumnBefore')"
            :active="false"
            icon="mdi-table-column-plus-before"
            @btnClic="editor.chain().focus().addColumnBefore().run()"
            :disabled="!editor.can().addColumnBefore()"
          />

          <editorButton
            :tooltip="$t('textEditor.addColumnAfter')"
            :active="false"
            icon="mdi-table-column-plus-after"
            @btnClic="editor.chain().focus().addColumnAfter().run()"
            :disabled="!editor.can().addColumnAfter()"
          />

          <editorButton
            :tooltip="$t('textEditor.deleteColumn')"
            :active="false"
            icon="mdi-table-column-remove"
            @btnClic="editor.chain().focus().deleteColumn().run()"
            :disabled="!editor.can().deleteColumn()"
          />

           <v-divider vertical class="editorButtonSeparator" />

          <editorButton
            :tooltip="$t('textEditor.addRowBefore')"
            :active="false"
            icon="mdi-table-row-plus-before"
            @btnClic="editor.chain().focus().addRowBefore().run()"
            :disabled="!editor.can().addRowBefore()"
          />

          <editorButton
            :tooltip="$t('textEditor.addRowAfter')"
            :active="false"
            icon="mdi-table-row-plus-after"
            @btnClic="editor.chain().focus().addRowAfter().run()"
            :disabled="!editor.can().addRowAfter()"
          />

          <editorButton
            :tooltip="$t('textEditor.deleteRow')"
            :active="false"
            icon="mdi-table-row-remove"
            @btnClic="editor.chain().focus().deleteRow().run()"
            :disabled="!editor.can().deleteRow()"
          />

           <v-divider vertical class="editorButtonSeparator" />

          <editorButton
            :tooltip="$t('textEditor.toggleCellMerge')"
            :active="false"
            icon="mdi-table-merge-cells"
            @btnClic="editor.chain().focus().mergeCells().run()"
            :disabled="!editor.can().mergeCells()"
          />

          <editorButton
            :tooltip="$t('textEditor.splitCell')"
            :active="false"
            icon="mdi-table-split-cell"
            @btnClic="editor.chain().focus().splitCell().run()"
            :disabled="!editor.can().splitCell()"
          /> 
          
           <v-divider vertical class="editorButtonSeparator" />

           <editorButton
            :tooltip="$t('textEditor.deleteTable')"
            :active="false"
            icon="mdi-table-remove"
            @btnClic="editor.chain().focus().deleteTable().run()"
            :disabled="!editor.can().deleteTable()"
            iconColor="error"
          />
        </v-row>
      </v-card>
    </template>
    <editorLinkDialog
      v-if="insertLinkDialog"
      @close="insertLinkDialog = false"
      @insertUrl="insertUrl"
      :editedUrl="editedUrl"
      :editedText="editedText"
    />
  </div>
</template>

<script>
// Custom components
import editorButton from "./editor_button";
import editorLinkDialog from "./editor_link_dialog.vue";
import { BubbleMenu } from "@tiptap/vue-2";

export default {
  name: "editor_toolbar",
  components: {
    editorButton,
    editorLinkDialog,
    BubbleMenu,
  },
  props: {
    editor: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      insertLinkDialog: false,
      editedUrl: "",
      editedText: "",
      newTableHeightDefault: 3,
      newTableWidthDefault: 2,
      newTableHeightSelection: 1,
      newTableWidthSelection: 1,
      newTableGridWidth: 5,
      newTableGridHeight: 5,
      newTableGridSizeMax: 16,
      newTableGridSizeMin: 5,
    };
  },
  computed: {},
  methods: {
    setTableSelectionGridSize(x, y) {
      console.log("hover event fired!");
      this.newTableWidthSelection = x;
      this.newTableHeightSelection = y;

      // Allow bigger selection when cursor is at the edges
      if (
        x >= this.newTableGridWidth &&
        this.newTableGridWidth < this.newTableGridSizeMax
      ) {
        this.newTableGridWidth++;
      }
      if (
        y >= this.newTableGridHeight &&
        this.newTableGridHeight < this.newTableGridSizeMax
      ) {
        this.newTableGridHeight++;
      }

      // Reduce grid size when big size not needed
      if (
        x < this.newTableGridWidth - 1 &&
        this.newTableGridWidth > this.newTableGridSizeMin
      ) {
        this.newTableGridWidth = x + 1;
      }
      if (
        y < this.newTableGridHeight - 1 &&
        this.newTableGridHeight > this.newTableGridSizeMin
      ) {
        this.newTableGridHeight = y + 1;
      }

      // Clamp values to be sure;
      this.newTableGridWidth = Math.max(
        this.newTableGridSizeMin,
        Math.min(this.newTableGridWidth, this.newTableGridSizeMax)
      );

      this.newTableGridHeight = Math.max(
        this.newTableGridSizeMin,
        Math.min(this.newTableGridHeight, this.newTableGridHeight)
      );
    },
    editUrlDialog() {
      const state = this.editor.state;

      // get marks, if any from selected area
      const { from, to } = state.selection;

      console.log(state.selection.$anchor.node(0));

      this.editedText = "";

      let marks = [];
      state.doc.nodesBetween(from, to, (node) => {
        marks = [...marks, ...node.marks];
      });

      const mark = marks.find((markItem) => markItem.type.name === "link");
      if (mark && mark.attrs.href) {
        this.editedUrl = mark.attrs.href;
      } else {
        this.editedUrl = "";
      }

      this.insertLinkDialog = true;
    },
    removeUrl() {
      this.editor.chain().focus().unsetLink().run();
    },
    insertUrl(event) {
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: event.url })
        .run();
      this.insertLinkDialog = false;
    },
    insertUrlDialog() {
      this.editedUrl = "";
      const state = this.editor.state;
      const { from, to } = state.selection;
      this.editedText = state.doc.textBetween(from, to, " ");

      this.insertLinkDialog = true;
    },
  },
};
</script>

