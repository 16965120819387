<template>
  <v-card flat style="min-height: 410px">
    <!-- Title and close button-->
    <v-card-title
      class="px-4 justify-space-between py-4 pb-0"
      v-if="card.archived"
    >
      <v-alert
        type="info"
        elevation="0"
        width="calc(100% - 56px)"
        class="ma-0 caption"
        color="purple_alert"
      >
        <span class="caption purple_alert_text--text">
          {{ $t("cards.cardIsArchived") }}
        </span>

        <template slot="prepend">
          <v-icon class="mr-4" color="purple_alert_text"
            >mdi-archive-outline</v-icon
          >
        </template>
      </v-alert>
      <v-btn v-if="!hideCloseBtn" @click="closeDialog()" small depressed icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>

    <v-card-title class="px-4 justify-space-around py-2 pb-4">
      <v-row wrap no-gutters align="center">
        <v-col cols="auto" class="grow" >
          <v-text-field
            v-model="card.title"
            background-color="transparent"
            :placeholder="$t('cards.noTitle')"
            class="title cardTitlePlaceholder focusedCardEditorField"
            hide-details
            solo
            flat
            :class="{
              'mr-4': !card.archived,
            }"
            :autofocus="!editedCard"
            autocomplete="off"
            ref="card-title"
            @blur="saveCardField('title')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="auto"  class=" shrink justify-space-around" align="end">
          <!-- SYNC MESSAGE -->
          <v-fade-transition mode="in-out">
            <v-chip
              color="transparent"
              small
              class="caption mr-4"
              v-show="syncMessage"
              style=""
            >
              <v-icon
                :class="{
                  'success--text': mutationState == 'success',
                  'error--text': mutationState == 'error',
                  'mr-2': true,
                }"
                v-if="mutationLoading == false"
              >
                {{ syncIcon }}
              </v-icon>
              <v-progress-circular
                class="mr-2"
                v-else
                color="button_blue"
                indeterminate
                size="12"
                width="1"
              ></v-progress-circular>
              {{ syncMessage }}
            </v-chip>
          </v-fade-transition>

          <v-btn
            v-if="!hideCloseBtn && !card.archived"
            @click="closeDialog()"
            small
            depressed
            icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-col>
      </v-row>

      <!-- <contextualMenu
        :actions="cardContextualMenuActions"
        @actionClic="cardContextualMenuHandler"
      /> -->
    </v-card-title>
    <!-- <v-divider></v-divider> -->

    <v-card-text
      id="cardEditorScrollArea"
      class="cardEditorScrollableContent px-4"
    >
      <v-row wrap no-gutters>
        <v-col cols="12" md="9">
          <!-- Decisions -->
          <template v-if="card.decisions.length || show_new_decision">
            <div class="mb-8">
              <h5 class="pl-4 pb-2 cardSubtitle">
                <v-icon
                  small
                  class="pr-2"
                  color="cardSubtitle"
                  style="padding-bottom: 2px"
                >
                  mdi-account-voice
                </v-icon>
                {{ $t("feedItemsFilter.decisions") }} ({{
                  card.decisions.length
                }})
              </h5>

              <draggable
                v-model="sortedDecisions"
                @change="updateItemsOrder($event)"
                handle=".handle"
                @start="dragging = true"
                @end="dragging = false"
                v-bind="dragOptions"
              >
                <transition-group
                  type="transition"
                  :name="!dragging ? 'flip-list' : null"
                >
                  <div
                    v-for="(decision, index) in sortedDecisions"
                    :key="'d_' + index"
                    :id="'d_' + index"
                  >
                    <v-hover :disabled="dragging">
                      <v-row
                        wrap
                        no-gutters
                        class="px-3 pt-0"
                        justify="start"
                        align="center"
                      >
                        <v-btn
                          small
                          icon
                          class="handle"
                          color="mid_grey darken-1"
                          style="transition: none"
                        >
                          <!-- :class="{ invisibleElement: !hover }" -->
                          <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                        <v-col class="decisionBorder pa-0 pr-0 pl-1 ml-2">
                          <v-textarea
                            :value="decision.content"
                            @input="(v) => (decision.content = v)"
                            background-color="transparent"
                            :placeholder="
                              $t('textEditor.writeYourDecisionHere')
                            "
                            auto-grow
                            rows="1"
                            :hide-details="decision.content.length < 241"
                            counter="240"
                            solo
                            flat
                            dense
                            :ref="'d_' + decision.id"
                            class="focusedCardEditorField textCardDecision"
                            @blur="saveFeedItem(decision, ...arguments)"
                            @keydown.enter.prevent="
                              $refs['d_' + decision.id][0].blur()
                            "
                            append-icon="mdi-delete-outline"
                            @click:append="removeDecision(decision, index)"
                            :rules="[rule_max_decision_length]"
                          >
                            <template slot="append">
                              <contextualMenu
                                :actions="contextualMenuActions"
                                :disabledActions="
                                  isLastItem ? ['convert_to_card'] : []
                                "
                                @actionClic="
                                  contextualMenuHandler(
                                    decision,
                                    index,
                                    ...arguments
                                  )
                                "
                              />
                            </template>
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-hover>
                  </div>
                </transition-group>
              </draggable>

              <!-- New decision -->
              <div v-show="show_new_decision" id="newDecision">
                <v-row
                  wrap
                  no-gutters
                  class="px-3 pt-2 ml-7"
                  justify="start"
                  align="center"
                >
                  <v-col class="decisionBorder pa-0 pr-0 pl-1 ml-2">
                    <v-textarea
                      :value="newDecision.content"
                      @input="(v) => (newDecision.content = v)"
                      ref="newDecision"
                      background-color="transparent"
                      :placeholder="$t('textEditor.writeYourDecisionHere')"
                      auto-grow
                      rows="1"
                      :hide-details="newDecision.content.length < 241"
                      counter="240"
                      solo
                      flat
                      dense
                      class="focusedCardEditorField textCardDecision"
                      @blur="saveFeedItem(newDecision, ...arguments)"
                      @keydown.enter.prevent="$refs.newDecision.blur()"
                      :autofocus="autofocus_new_decision"
                      :rules="[rule_max_decision_length]"
                      append-icon="mdi-close"
                      @click:append="hideNewDecision('Append text area')"
                    >
                      <!-- <template slot="append">
                    <v-icon @mousedown="hideNewDecision">mdi-close</v-icon>
                  </template> -->
                    </v-textarea>
                  </v-col>
                </v-row>
              </div>

              <v-btn
                small
                depressed
                class="
                  addNewDecisionButton
                  dark_grey--text
                  mx-2
                  mt-4
                  px-2
                  ml-12
                "
                @click="addItem('decision')"
                tabindex="-1"
              >
                <v-icon small class="pr-1"> mdi-plus </v-icon>
                {{ $t("textEditor.addDecision") }}
              </v-btn>
            </div>
          </template>

          <!-- Content -->
          <div class="mb-8">
            <h5 class="pl-4 cardSubtitle" small>
              <v-icon
                small
                class="pr-2"
                color="dark_grey"
                style="padding-bottom: 2px"
              >
                mdi-text
              </v-icon>
              {{ $t("feedItems.note") }}
            </h5>
            <div
              class="ma-2 textCardContent"
              :class="{
                focusedCardEditorField: !!editor,
              }"
            >
              <editor-content class="pa-2 feed_item_html" :editor="editor" />
            </div>
          </div>

          <!-- Tasks -->
          <template v-if="card.tasks.length || show_new_task">
            <div class="mb-8">
              <h5 class="pl-4 pb-2 cardSubtitle">
                <v-icon
                  small
                  class="pr-2"
                  color="cardSubtitle"
                  style="padding-bottom: 2px"
                >
                  mdi-order-bool-ascending-variant
                </v-icon>
                {{ $t("misc.checklist") }} ({{ card.tasks.length }})
              </h5>

              <draggable
                v-model="sortedTasks"
                @change="updateItemsOrder($event)"
                handle=".handle"
                @start="dragging = true"
                @end="dragging = false"
                v-bind="dragOptions"
              >
                <transition-group
                  type="transition"
                  :name="!dragging ? 'flip-list' : null"
                >
                  <div
                    v-for="(task, index) in sortedTasks"
                    :key="'t_' + index"
                    class="cardTaskText"
                    :id="'t_' + index"
                  >
                    <v-hover :disabled="dragging">
                      <v-row
                        wrap
                        no-gutters
                        class="px-3 mt-0"
                        justify="start"
                        align="center"
                      >
                        <v-btn
                          small
                          icon
                          class="handle"
                          color="mid_grey darken-1"
                          style="transition: none"
                        >
                          <!-- :class="{ invisibleElement: !hover }" -->
                          <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>

                        <v-textarea
                          :value="task.content"
                          @input="(v) => (task.content = v)"
                          background-color="transparent"
                          :placeholder="$t('textEditor.writeYourTaskHere')"
                          auto-grow
                          rows="1"
                          hide-details
                          solo
                          flat
                          dense
                          class="focusedCardEditorField textCardTask"
                          @blur="saveFeedItem(task, ...arguments)"
                          @keydown.enter.prevent="addItem('task')"
                        >
                          <!--  append-icon="mdi-delete-outline"
                      @click:append="removeTask(task, index)" -->
                          <template slot="prepend-inner">
                            <!-- <v-simple-checkbox
                          color="dark_grey lighten-2"
                          class="ma-0"
                          v-model="task.done"
                          @click="saveFeedItem(task, ...arguments)"
                          style="line-height: unset"
                        ></v-simple-checkbox> -->

                            <v-btn
                              small
                              icon
                              style="top: -2px"
                              :color="task.done ? 'success' : '#aaa'"
                              @click.stop="
                                task.done = !task.done;
                                saveFeedItem(task, ...arguments);
                              "
                            >
                              <v-icon size="20">
                                {{
                                  task.done
                                    ? "mdi-check-circle"
                                    : "mdi-check-circle-outline"
                                }}
                              </v-icon>
                            </v-btn>
                          </template>

                          <template slot="append">
                            <v-row no-gutters wrap align="center">
                              <date-and-user-picker
                                :selectedUsers="task.users"
                                :date="task.due_date"
                                @onUserSelected="
                                  addUserToTask(task.id, ...arguments)
                                "
                                @onUserUnselected="
                                  removeUserFromTask(task, ...arguments)
                                "
                                @dateSelected="
                                  updateTaskDate(task.id, ...arguments)
                                "
                              />

                              <task-priority
                                class="ml-2"
                                :readonly="false"
                                :value="task.priority"
                                @selectedValue="
                                  updateTaskPriority(task, ...arguments)
                                "
                              />

                              <contextualMenu
                                :actions="contextualMenuActions"
                                :disabledActions="
                                  isLastItem ? ['convert_to_card'] : []
                                "
                                @actionClic="
                                  contextualMenuHandler(
                                    task,
                                    index,
                                    ...arguments
                                  )
                                "
                              />
                            </v-row>
                          </template>
                        </v-textarea>
                      </v-row>
                    </v-hover>
                  </div>
                </transition-group>
              </draggable>
              <div v-show="show_new_task" id="newTask">
                <v-row
                  wrap
                  no-gutters
                  class="px-3 pt-2 ml-4"
                  justify="start"
                  align="center"
                >
                  <v-col class="pa-0 pr-0 pl-1 ml-2">
                    <v-text-field
                      :value="newTask.content"
                      @input="(v) => (newTask.content = v)"
                      background-color="transparent"
                      ref="newTask"
                      :placeholder="$t('textEditor.writeYourTaskHere')"
                      auto-grow
                      rows="1"
                      hide-details
                      solo
                      flat
                      dense
                      class="focusedCardEditorField textCardTask"
                      @blur="saveFeedItem(newTask, ...arguments)"
                      :autofocus="autofocus_new_task"
                      @keydown.enter.prevent="$refs.newTask.blur()"
                      append-icon="mdi-close"
                      @click:append="hideNewTask('Append text area')"
                    >
                      <!--  append-icon="mdi-delete-outline"
              @click:append="removeTask(task, index)" -->
                      <template slot="prepend-inner">
                        <!-- <v-simple-checkbox
                      color="dark_grey lighten-2"
                      class="ma-0"
                      v-model="newTask.done"
                      @click="saveFeedItem(newTask, ...arguments)"
                      style="line-height: unset"
                    ></v-simple-checkbox> -->

                        <v-btn
                          small
                          icon
                          :color="newTask.done ? 'success' : '#aaa'"
                          @click.stop="
                            newTask.done = !newTask.done;
                            saveFeedItem(newTask, ...arguments);
                          "
                        >
                          <v-icon size="20">
                            {{
                              newTask.done
                                ? "mdi-check-circle"
                                : "mdi-check-circle-outline"
                            }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-btn
                small
                depressed
                color="light_grey"
                class="addNewTaskButton dark_grey--text mx-2 mt-4 px-2 ml-12"
                @click="addItem('task')"
              >
                <v-icon small class="pr-1"> mdi-plus </v-icon>
                {{ $t("textEditor.addTask") }}
              </v-btn>
            </div>
          </template>

          <!-- Files -->
          <div class="mb-8" v-if="card.files.length || uploadingFiles.length">
            <h5 class="pl-4 cardSubtitle">
              <v-icon
                small
                class="pr-2"
                color="dark_grey"
                style="padding-bottom: 2px"
              >
                mdi-paperclip
              </v-icon>
              {{ $t("feedItems.attachments") }} ({{ card.files.length }})
            </h5>

            <v-row wrap no-gutters class="pt-2">
              <template v-for="(file, index) in card.files">
                <!-- Uploaded Files -->
                <v-col
                  xs12
                  cols="12"
                  class="d-flex pl-4 pr-6 mt-0"
                  :key="'d_' + index"
                >
                  <feed-item-file-show
                    :file="file"
                    @deleteFile="deleteFile(index, ...arguments)"
                  />
                </v-col>
              </template>
            </v-row>
            <v-row wrap no-gutters class="pt-2">
              <template v-for="(e, index) in uploadingFiles">
                <!-- Files being uploaded -->
                <v-col
                  xs12
                  cols="12"
                  class="d-flex pl-4 pr-6 mt-0"
                  :key="'d_' + index"
                >
                  <feed-item-file-upload
                    :file="e.fileToUpload"
                    @uploadCompleted="createCardFile($event, index)"
                  />
                </v-col>
              </template>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" md="3">
          <div class="stickySidebar">
            <v-row wrap no-gutters>
              <v-col cols="12" class="py-1">
                <v-subheader class="denseSubheader px-1">
                  {{ $t("cards.addToCard") }}:
                </v-subheader>
              </v-col>

              <!-- Tags -->
              <v-col cols="6" md="12" class="py-1 px-1" v-if="card.id">
                <cardTagsMenu
                  :cardTags="card.card_tags"
                  :cardId="card.id"
                  ref="tagsMenu"
                />
              </v-col>

              <!-- Decisions -->
              <v-col cols="6" md="12" class="py-1 px-1">
                <v-btn
                  block
                  depressed
                  small
                  color="light_grey"
                  class="justify-start dark_grey--text addNewDecisionButton"
                  @click="addItem('decision')"
                >
                  <v-icon small left>mdi-account-voice</v-icon>
                  {{ $t("textEditor.addDecisions") }}
                </v-btn>
              </v-col>

              <!-- Task list -->
              <v-col cols="6" md="12" class="py-1 px-1">
                <v-btn
                  block
                  depressed
                  small
                  color="light_grey"
                  class="justify-start dark_grey--text addNewTaskButton"
                  @click="addItem('task')"
                >
                  <v-icon small left>mdi-check-circle-outline</v-icon>
                  {{ $t("textEditor.addTasks") }}
                </v-btn>
              </v-col>

              <!-- Attachment -->
              <v-col cols="6" md="12" class="py-1 px-1">
                <v-btn
                  block
                  depressed
                  small
                  color="light_grey"
                  class="justify-start dark_grey--text"
                  @click="showUploadFileInput()"
                >
                  <v-icon small left>mdi-paperclip</v-icon>
                  {{ $t("feedItems.attach") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row wrap no-gutters class="mt-4" v-if="editedCard">
              <v-col cols="12" class="py-1">
                <v-subheader class="denseSubheader px-1">
                  {{ $t("cards.actions") }}:
                </v-subheader>
              </v-col>

              <!-- Archive -->
              <v-col cols="6" md="12" class="py-1 px-1">
                <v-btn
                  v-if="!card.archived"
                  block
                  depressed
                  small
                  color="light_grey"
                  class="justify-start dark_grey--text"
                  @click="archiveCard"
                >
                  <v-icon small left>mdi-archive-outline</v-icon>
                  {{ $t("cards.archive") }}
                </v-btn>

                <v-btn
                  v-else
                  block
                  depressed
                  small
                  color="light_grey"
                  class="justify-start dark_grey--text"
                  @click="unarchiveCard"
                >
                  <v-icon small left>mdi-archive-minus-outline</v-icon>
                  {{ $t("cards.unarchive") }}
                </v-btn>
              </v-col>

              <!-- Delete card -->
              <v-col cols="6" md="12" class="py-1 px-1">
                <v-btn
                  block
                  depressed
                  small
                  color="light_grey"
                  class="justify-start error--text"
                  @click="deleteCard()"
                >
                  <v-icon small left>mdi-delete-outline</v-icon>
                  {{ $t("cards.deleteCard") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <!-- History -->
      <!-- <div class="mb-8" v-if="card.id && false">
        <h5 class="pl-4" small color="light_grey">
          <v-icon
            small
            class="pr-2"
            color="dark_grey"
            style="padding-bottom: 2px"
          >
            mdi-history
          </v-icon>
          {{ $t("meetings.history") }}
        </h5>
      </div> -->
    </v-card-text>

    <div class="editorCardActions">
      <!-- <json-viewer :value="card" /> -->
      <!-- <v-divider></v-divider> -->
      <v-card-actions style="height: 44px">
        <v-row wrap no-gutters>
          <editorToolBar :editor="editor" />
          <v-spacer></v-spacer>
          <v-chip
            @click="$refs.tagsMenu.showMenu = true"
            label
            small
            class="px-2 mx-1"
            v-for="(tag, index) in card.tags.filter((t) => {
              return t != null;
            })"
            dark
            :color="tag.color"
            :key="'_t'+index"
          >
            {{ tag.name }}</v-chip
          >
          <!-- Add buttons -->
          <!-- Colored background test -->
          <!-- class="item_decision_background item_decision--text mr-2" -->
          <!-- <v-btn
            small
            depressed
            color="light_grey"
            class="dark_grey--text mx-2 addNewDecisionButton"
            @click="addItem('decision')"
            v-show="card.decisions.length == 0"
          >
            <v-icon small class="pr-2"> mdi-account-voice </v-icon>
            {{ $t("textEditor.addDecisions") }}
          </v-btn>

          <v-btn
            small
            depressed
            color="light_grey"
            class="dark_grey--text mx-2 addNewTaskButton"
            @click="addItem('task')"
            v-show="card.tasks.length == 0"
          >
            <v-icon small class="pr-2"> mdi-checkbox-multiple-marked </v-icon>
            {{ $t("textEditor.addTasks") }}
          </v-btn>

          <v-btn
            small
            depressed
            color="light_grey"
            class="dark_grey--text mx-2"
            @click="showUploadFileInput()"
          >
            <v-icon small class="pr-2">mdi-paperclip</v-icon>
            {{ $t("feedItems.attach") }}
          </v-btn> -->
        </v-row>
      </v-card-actions>
    </div>
    <input
      type="file"
      :multiple="true"
      ref="uploadInput"
      @change="uploadInputChange"
    />
  </v-card>
</template>

<script>
import editor from "@/mixins/editor";
import rules from "@/mixins/rules";
import CREATE_CARD from "@/graphql/cards/create.gql";
import UPDATE_CARD from "@/graphql/cards/update.gql";
import CREATE_FEED_ITEM from "@/graphql/feed_items/create.gql";
import UPDATE_FEED_ITEM from "@/graphql/feed_items/update.gql";
import DESTROY_FEED_ITEM from "@/graphql/feed_items/destroy.gql";
import CONVERT_TO_CARD_FEED_ITEM from "@/graphql/feed_items/convert_to_card.gql";
import CREATE_FEED_ITEM_USER from "@/graphql/feed_item_users/create.gql";
import contextualMenu from "../shared/contextualMenu.vue";
import draggable from "vuedraggable";

import feedItemFileUpload from "../feed_items/feed_item_file_upload.vue";
import feedItemFileShow from "../feed_items/feed_item_file_show.vue";
import fileItem from "../shared/fileItem.vue";
import { mapActions, mapGetters } from "vuex";
import DateAndUserPicker from "../shared/dateAndUserPicker.vue";
import * as api from "@/services/no-meetings.api";
import taskPriority from "../tasks/task_priority.vue";
import cardTagsMenu from "./card_tags_menu.vue";

export default {
  name: "card_editor",
  mixins: [rules, editor],
  components: {
    contextualMenu,
    draggable,
    feedItemFileUpload,
    fileItem,
    feedItemFileShow,
    DateAndUserPicker,
    taskPriority,
    cardTagsMenu,
  },
  props: {
    currentMeetingId: {
      type: String,
      required: false,
      default: "",
    },
    hideCloseBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tagsMenuDomAttachId: null,
      dragging: false,
      autofocus_title: true,
      loadingCreation: false,
      lastContentSend: "",
      mutationLoading: false,
      mutationMessageTimeout: null,
      mutationState: "", // is either empty, 'success', or 'error'
      card: {
        id: null,
        title: "",
        content: "",
        decisions: [],
        tasks: [],
        tags: [],
        card_tags: [],
        files: [],
      },
      uploadingFiles: [],
      newDecision: {
        content: "",
        feed_item_type: "decision",
      },
      newTask: {
        users: [],
        due_date: null,
        done: false,
        content: "",
        feed_item_type: "task",
      },
      show_new_decision: false,
      show_new_task: false,
      autofocus_new_decision: false,
      autofocus_new_task: false,
      contextualMenuActions: [
        {
          name: "convert_to_card",
          text: this.$t("textEditor.convertToCard"),
          icon: "mdi-card-bulleted-outline",
          function: this.convert_item_as_card,
        },
        {
          name: "delete_item",
          text: this.$t("misc.delete"),
          icon: "mdi-delete",
          function: this.delete_item,
        },
      ],
      cardContextualMenuActions: [
        {
          name: "delete_card",
          text: this.$t("cards.deleteCard"),
          icon: "mdi-delete",
          function: this.deleteCard,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "meetingId",
      "editedCardId",
      "editedCard",
      "editedCardTopicId",
      "editedCardFollowUpId",
      "cardRefresh",
    ]),
    syncMessage() {
      if (this.mutationLoading) {
        return this.$t("cards.saving");
      } else {
        switch (this.mutationState) {
          case "":
            return "";
          case "success":
            return this.$t("cards.noteSaved");
          case "error":
            return this.$t("cards.saveError");
          default:
            return "";
        }
      }
    },
    syncIcon() {
      switch (this.mutationState) {
        case "":
          return "";
        case "success":
          return "mdi-cloud-check";
        case "error":
          return "mdi-cloud-off-outline";
        default:
          return "";
      }
    },
    isLastItem() {
      return (
        this.card.decisions.length +
          this.card.tasks.length +
          this.card.files.length ===
          1 &&
        !this.card.content &&
        !this.card.title
      );
    },
    canAddDecision() {
      return !this.card.decisions.map((d) => d.content).includes("");
    },
    canAddTask() {
      return !this.card.tasks.map((t) => t.content).includes("");
    },
    sortedDecisions: {
      get() {
        return this.card.decisions.sort((a, b) => {
          return a.position > b.position ? 1 : -1;
        });
      },
      set(value) {
        for (var i = 0; i < value.length; i++) value[i].position = i + 1;
        this.updateDecisionsPos(this.card);
      },
    },
    sortedTasks: {
      get() {
        return this.card.tasks.sort((a, b) => {
          return a.position > b.position ? 1 : -1;
        });
      },
      set(value) {
        for (var i = 0; i < value.length; i++) value[i].position = i + 1;
        this.updateTasksPos(this.card);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    },
    isBlank() {
      return !this.card.title && !this.card.content;
    },
    hasChanged() {
      return (
        !this.editedCard ||
        this.card.title != this.editedCard.title ||
        this.card.content != this.editedCard.content ||
        this.card.archived != this.editedCard.archived
      );
    },
    /* maxDecisionsPos() {
      return Math.max.apply(
        null,
        this.card.decisions.map((e) => e.position)
      );
    }, */
  },
  methods: {
    ...mapActions(["updateDecisionsPos", "updateTasksPos", "setEditedCardId"]),
    showTagsMenu(dom_id) {
      this.tagsMenuDomAttachId = dom_id;
      this.$refs.cardTagsMenu.showMenu = true;
    },
    archiveCard() {
      this.card.archived = true;
      this.mutateCard(UPDATE_CARD, { id: this.card.id, archived: true });
      this.saveCardField("archived");
    },
    unarchiveCard() {
      this.card.archived = false;
      this.mutateCard(UPDATE_CARD, { id: this.card.id, archived: false });
    },
    updateTaskDate(task_id, date) {
      let item = {
        id: task_id,
        last_update_at_meeting_id: this.currentMeetingId,
        due_date: new Date(date).getTime(),
      };
      this.mutateFeedItem(UPDATE_FEED_ITEM, item);
    },
    addUserToTask(task_id, user_id) {
      this.$apollo
        .mutate({
          mutation: CREATE_FEED_ITEM_USER,
          variables: {
            feed_item_id: task_id,
            user_id: user_id,
          },
        })
        .then(() => {
          this.$emit("refreshTasks");
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    removeUserFromTask(task, user_id) {
      let feed_item_user_id = task.feed_item_users.find(
        (e) => e.user_id == user_id
      ).id;
      this.destroyFeedItemUser(feed_item_user_id);
    },
    async destroyFeedItemUser(id) {
      try {
        this.loading = true;
        const { data } = await api.destroy(id, "feed_item_user");
        this.loading = false;
        this.$emit("refreshTasks");
      } catch (err) {
        this.loading = false;
        this.sendError(err);
        console.error(err);
      }
    },
    updateItemsOrder({ added, removed, moved }) {
      if (!moved) return;

      this.$apollo
        .mutate({
          mutation: UPDATE_FEED_ITEM,
          variables: {
            id: moved.element.id,
            position: moved.newIndex + 1,
            last_update_at_meeting_id: this.currentMeetingId,
          },
        })
        .then(() => {})
        .catch((error) => {
          this.sendError(error);
        });
    },
    contextualMenuHandler(item, index, event) {
      console.log("handler:", item, event);
      event.function(item, index);
    },
    cardContextualMenuHandler(event) {
      console.log(" card handler:", event);
      event.function();
    },
    deleteCard() {
      this.$emit("deleteCard", this.card);
    },
    convert_item_as_card(item, index) {
      console.log("CONVERT", item);
      return this.$apollo.mutate({
        mutation: CONVERT_TO_CARD_FEED_ITEM,
        variables: {
          id: item.id,
          last_update_at_meeting_id: this.currentMeetingId,
        },
      });
    },
    deleteFile(index, file) {
      console.log(index);
      this.card.files.splice(index, 1);
      if (file.id) this.removeFeedItem(file.id);
    },
    delete_item(item, index) {
      console.log("DELETE", item, index);
      switch (item.feed_item_type) {
        case "task":
          this.removeTask(item, index);
          break;
        case "decision":
          this.removeDecision(item, index);
          break;
        default:
          break;
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    addItem(type) {
      console.log("add item", type);
      if (type == "decision") {
        this.newDecision.content = "";
        this.autofocus_new_decision = true;
        this.show_new_decision = true;

        this.$nextTick(() => {
          this.$refs.newDecision.focus();
        });
      } else if (type == "task") {
        this.autofocus_new_task = true;
        this.newTask.content = "";
        this.newTask.done = false;
        this.show_new_task = true;
        this.$nextTick(() => {
          this.$refs.newTask.focus();
        });
        this.$emit("refreshTasks");
      }
    },
    removeDecision(decision, index) {
      this.card.decisions.splice(index, 1);
      if (decision.id) this.removeFeedItem(decision.id);
    },
    hideNewTask() {
      this.newTask.content = "";
      this.newTask.done = false;
      this.show_new_task = false;
    },
    hideNewDecision(source = "") {
      console.log("HIDE", source);
      this.newDecision.content = "";
      this.show_new_decision = false;
    },
    removeTask(task, index) {
      this.card.tasks.splice(index, 1);
      if (task.id) this.removeFeedItem(task.id);
    },
    async removeFeedItem(id) {
      this.loading = true;
      try {
        const { data } = await this.destroy_feed_item(id);
        this.loading = false;
        this.$emit("refreshTasks");
        this.$emit("taskDeleted", id);
      } catch (err) {
        this.loading = false;
        this.sendError(err);
        console.error(err);
      }
    },
    destroy_feed_item(id) {
      return this.$apollo.mutate({
        mutation: DESTROY_FEED_ITEM,
        variables: {
          id: id,
          last_update_at_meeting_id: this.currentMeetingId,
        },
      });
    },
    itemHasNoChanges(feed_item) {
      if (!feed_item.id) return false;

      let item = this.editedCard.feed_items.find((e) => e.id === feed_item.id);
      return (
        feed_item.content === item.content &&
        feed_item.done === item.done &&
        feed_item.due_date === item.due_date &&
        feed_item.position === item.position &&
        feed_item.priority === item.priority
      );
    },
    saveContent(content) {
      this.card.content = content;
      this.lastContentSend = content;
      this.saveCardField("content");
    },
    saveCardField(field) {
      if (!this.hasChanged || this.isBlank) return;

      if (this.loadingCreation) {
        /* We are waiting for the card to be created, delay this saveCardField call */
        console.log("delaying waiting for creation");
        setTimeout(() => this.saveCardField(field), 1000);
        return;
      }

      let mutation;
      let vars = {};
      vars[field] = this.card[field];
      vars.last_update_at_meeting_id = this.currentMeetingId;

      if (!this.card.id) {
        mutation = CREATE_CARD;
        vars.topic_id = this.card.topic_id;
        vars.follow_up_id = this.card.follow_up_id;
        this.loadingCreation = true;
      } else {
        vars.id = this.card.id;
        mutation = UPDATE_CARD;
      }

      this.mutateCard(mutation, vars);
    },
    mutateCard(mutation, vars) {
      this.mutationLoading = true;
      this.mutationState = "";
      clearTimeout(this.mutationMessageTimeout);

      this.$apollo
        .mutate({
          mutation: mutation,
          variables: vars,
        })
        .then(({ data }) => {
          this.displayMutationMessage("success");
          if (data.create_card) this.handleCardCreation(data.create_card.id);
          if (vars.archived) this.closeDialog();
        })
        .catch((error) => {
          this.displayMutationMessage("error");
          this.loadingCreation = false;
          this.sendError(error);
          console.log(error);
        })
        .finally(() => {
          this.mutationLoading = false;
        });
    },
    displayMutationMessage(type) {
      this.mutationState = type;
      this.mutationMessageTimeout = setTimeout(() => {
        this.mutationState = "";
      }, 3000);
    },
    updateTaskPriority(task, priority) {
      task.priority = priority;
      this.saveFeedItem(task, priority);
    },
    saveFeedItem(feed_item, event) {
      /* If no content is provided => cancel item creation */
      if (!feed_item.content && !feed_item.signed_blob_id) {
        if (feed_item.feed_item_type == "decision") {
          if (
            event.relatedTarget &&
            event.relatedTarget.classList &&
            !event.relatedTarget.classList.contains("addNewDecisionButton")
          )
            this.hideNewDecision("save feed item");
        } else if (feed_item.feed_item_type == "task") {
          if (
            event.relatedTarget &&
            event.relatedTarget.classList &&
            !event.relatedTarget.classList.contains("addNewTaskButton")
          )
            this.hideNewTask();
        }
        return;
      }
      /* If item has no changes => cancel item creation */
      if (this.itemHasNoChanges(feed_item)) return;

      if (this.loadingCreation) {
        /* We are waiting for the card to be created => delay this saveCardField call */
        console.log("delaying waiting for creation");
        setTimeout(() => this.saveFeedItem(feed_item, event), 1000);
        return;
      }

      feed_item.last_update_at_meeting_id = this.currentMeetingId;
      /* Item update */
      if (feed_item.id) return this.mutateFeedItem(UPDATE_FEED_ITEM, feed_item);

      /* Item creation */
      if (this.card.id) feed_item.card_id = this.card.id;
      else {
        /* We are creating the card in the same time we create the feed item */
        this.loadingCreation = true;
        feed_item.card_id = null;
        feed_item.topic_id = this.card.topic_id;
        feed_item.follow_up_id = this.card.follow_up_id;
      }

      /*
        // OPTIMISTIC
        feed_item.position = this.maxDecisionsPos + 1;
        this.card.decisions.push(feed_item);
      */
      this.mutateFeedItem(CREATE_FEED_ITEM, feed_item);
    },
    async mutateFeedItem(mutation, feed_item) {
      this.mutationLoading = true;
      this.mutationState = "";
      clearTimeout(this.mutationMessageTimeout);

      this.$apollo
        .mutate({
          mutation: mutation,
          variables: feed_item,
        })
        .then(({ data }) => {
          if (data.create_feed_item) {
            this.displayMutationMessage("success");
            this.handleCardCreation(data.create_feed_item.card_id);
            if (feed_item.feed_item_type == "decision") {
              this.autofocus_new_decision = true;
              this.newDecision.content = "";
              this.$refs.newDecision.focus();
            }
            if (feed_item.feed_item_type == "task") {
              this.autofocus_new_task = true;
              this.newTask.content = "";
              this.newTask.done = false;
              this.$refs.newTask.focus();
              this.$emit("refreshTasks");
            }
          }
        })
        .catch((error) => {
          this.displayMutationMessage("error");
          this.sendError(error);
          console.log(error);
          this.loadingCreation = false;
        })
        .finally(() => {
          this.mutationLoading = false;
        });
    },
    handleCardCreation(card_id) {
      this.setEditedCardId(card_id);
      this.loadingCreation = false;
    },
    showUploadFileInput() {
      this.$refs.uploadInput.click();
    },
    uploadInputChange(event) {
      const inputSelector = this.$refs.uploadInput;
      Array.from(inputSelector.files).forEach((file) => {
        this.uploadingFiles.push({
          card_id: this.card.id,
          topic_id: this.card.topic_id,
          follow_up_id: this.card.follow_up_id,
          feed_item_type: "file",
          fileToUpload: file,
        });
      });
      this.$refs.uploadInput.value = null;
    },
    createCardFile(event, index) {
      let front_id = Math.random().toString(36).substr(2, 5);
      this.uploadingFiles[index].front_id = front_id;
      let item = {
        card_id: this.card.id,
        feed_item_type: "file",
        content: front_id,
        done: false,
        signed_blob_id: event.blob.signed_id,
      };
      if (!this.card.id) {
        item.card_id = null;
        item.topic_id = this.card.topic_id;
        item.follow_up_id = this.card.follow_up_id;
      }
      this.mutateFeedItem(CREATE_FEED_ITEM, item);
    },
    copyObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    cleanUploadingFiles() {
      this.editedCard.files.map((file) => {
        this.uploadingFiles = this.uploadingFiles.filter(
          (e) => e.front_id !== file.content
        );
      });
    },
    loadCard() {
      console.log("loading card");
      if (!this.editedCard) {
        /* We are creating a new card */
        this.card.id = "";
        this.card.title = "";
        this.card.content = "";
        this.card.archived = false;
        this.card.decisions = [];
        this.card.tasks = [];
        this.card.card_tags = [];
        this.card.tags = [];
        this.card.files = [];
        this.card.topic_id = this.editedCardTopicId;
        this.card.follow_up_id = this.editedCardFollowUpId;
        this.uploadingFiles = [];
        this.setContent("");
      } else {
        /* We are editing an existing card */
        if (this.card.id !== this.editedCard.id) {
          /* We are opening another card */
          this.uploadingFiles = [];
          this.setContent(this.editedCard.content);
          this.card.title = this.editedCard.title;
        } else {
          /* We received a rehydratation of the current card or we are opening the same card again */
          this.cleanUploadingFiles();
          if (this.lastContentSend != this.card.content) {
            this.setContent(this.editedCard.content);
          } else {
            console.log("update content prevented");
          }
          /* Avoid rehydratation on title if it is currently edited */
          if (!this.$refs["card-title"]._data.hasFocused)
            this.card.title = this.editedCard.title;
        }
        this.card.id = this.editedCard.id;
        this.card.content = this.editedCard.content;
        this.card.archived = this.editedCard.archived;
        this.card.decisions = this.copyObject(this.editedCard.decisions);
        this.card.tasks = this.copyObject(this.editedCard.tasks);
        this.card.tags = this.copyObject(this.editedCard.tags);
        this.card.card_tags = this.copyObject(this.editedCard.card_tags);
        this.card.files = this.copyObject(this.editedCard.files);
        this.card.topic_id = null;
        this.card.follow_up_id = null;
      }
    },
  },
  mounted() {
    this.initEditor("");
    this.loadCard();
  },
  watch: {
    editedCard() {
      this.loadCard();
    },
  },
};
</script>

<style lang='scss' scoped>
input[type="file"] {
  display: none;
}
</style>

<style scoped>
.stickySidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}
</style>
