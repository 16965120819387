<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" small>
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-list dense class="pa-0 py-2" >

           <v-list-item
        :disabled="disabledActions.includes(action.name)"
        class="ultraDenseListItem"
        v-for="(action, index) in actions"
        :key="index"
        @click="$emit('actionClic', action, resourceId)"
      >
        <v-list-item-icon class="mr-2 ultraDenseListItemIcon">
          <v-icon small>{{ action.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ action.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "contextualMenu",
  mixins: [],
  components: {},
  props: {
    actions: {
      type: Array,
      required: true,
    },
    disabledActions: {
      type: Array,
      required: false,
      default: () => [],
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-dots-vertical",
    },
    resourceId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>



