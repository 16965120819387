<template>
 
  <viewer
    :images="images"
    :options="options"
    class="viewer"
    ref="viewer"
    @inited="inited"
    
    v-if="images && images.length"
    
    >
    <img
      v-for="im in images"
      :src="im"
      :data-source="im"
      :key="im"
      class="image"
      style="display: none !important"
    />
  
  </viewer>

  <!--       imageViewerOptions: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
 -->
</div>
</template>

<script>
import Vue from "vue";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);
export default {
  props: {
    images: { type: Array, default: () => [] },
    selectedImageIndex: {
      type: Number,
      default: 0,
   
    },
     fullscreen: {
      type: Boolean
    }
  },
  data() {
    return {
      self: this,
      options: {
        inline: true,
        fullscreen: false,
        title: false,
        button: false,
        viewed: this.viewed,
      },
    };
  },
  methods: {
    viewed(e) {
      console.log("viewed image index:", e);
      this.$emit("viewedIndexChange", e);
    },
    inited(viewer) {
      this.$viewer = viewer;
      this.$viewer.view(this.selectedImageIndex);
    },
    view() {
      this.$viewer.view(this.selectedImageIndex);
    },
    show(images) {
      this.view();
    },
  },
  watch: {
    selectedImageIndex() {
      console.log("click on view");
      this.view();
    },
    fullscreen() {
      this.options.fullscreen = this.fullscreen;
    }
  },
  created() {
    console.log("created image viewer");
  },
};
</script>


<style lang="scss" scoped>
// Hide images in background as file viewer wrapper already have selector
.image {
  display: none;
}
</style>

<style>
.viewer-loading > img {
  display: none; /* hid when loading */
}

.viewer-backdrop {
  background-color: white !important;
}
.viewer-navbar {
  background-color: #eee;
  border-top: 1px #ddd solid;
}

.viewer-container,
.viewer-backdrop {
  width: 100% !important;
  height: 100% !important;
}
</style>