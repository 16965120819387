<template>
  <v-hover v-slot="{ hover }">
    <v-row
      class="pb-0 my-0 mb-0 px-0"
      :class="{ light_grey: hover }"
      wrap
      no-gutters
      justify="start"
      align="start"
    >
      <v-col cols="12" class="pa-0">
        <v-card flat class="pa-0">
          <!-- FILE -->
          <v-list dense class="pa-0">
            <v-list-item
              style="align-items: normal; cursor: default"
              class="pa-0 px-1 ultraDenseListItem"
              @click="() => {}"
            >
              <v-list-item-icon class="justify-start mr-0">
                <v-progress-circular
                  size="14"
                  width="2"
                  :indeterminate="uploadProgressDone == 0"
                  :value="progressValue"
                  :color="progressColor"
                  v-if="!uploadComplete && !uploadFailed"
                />
                <img
                  v-else
                  height="14"
                  width="14"
                  :src="fileIconFromUrl(uploadFileName)"
                />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ uploadFileName }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text class="pt-1 text-right align-self-start">
                <span v-if="!uploadComplete">
                  {{ humanFileSize(uploadProgressDone) }}/
                </span>
                {{ humanFileSize(file.size) }}
                <strong class="pl-4"> {{ progressValue }}% </strong>
              </v-list-item-action-text>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
import upload from "@/mixins/upload.js";
import files from "@/mixins/files.js";

export default {
  name: "feedItemFileUpload",
  mixins: [upload, files],
  data() {
    return {
      displayAsFeedItem: false,
    };
  },
  props: {
    file: {
      required: true,
      type: File,
    },
  },
  created() {
    this.upload(this.file);
  },
};
</script>


