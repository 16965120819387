<template>
  <v-card flat class="pa-0 ma-0">
    <v-card-title class="pa-6 pb-0 body-1" v-if="card.archived">
      <v-alert
        type="info"
        elevation="0"
        width="100%"
        class="ma-0 caption"
        color="purple_alert"
      >
        <span class="caption purple_alert_text--text">
          {{ $t("cards.cardIsArchived") }}
        </span>

        <template slot="prepend">
          <v-icon class="mr-4" color="purple_alert_text"
            >mdi-archive-outline</v-icon
          >
        </template>
      </v-alert>
    </v-card-title>
    <!-- Titre -->

    <div class="px-7 py-1">
      <v-card-title class="pa-0">
        <span class="pt-4" v-if="card.title">{{ card.title }}</span>
        <v-spacer></v-spacer>
        <slot name="cardHeaderActions"></slot>
      </v-card-title>
      <!-- Decisions -->
      <div v-if="card.decisions.length" class="py-2 pt-3">
        <div
          v-for="decision in sortedDecisions"
          :key="decision.id"
          class="my-2"
        >
          <!-- <div class="decisionItemBar"></div> -->
          <div class="cardDecisionText textCardDecision">
            {{ decision.content }}
          </div>
        </div>
      </div>

      <!-- Description/Note -->
      <div
        class="textCardContent editor__content feed_item_html py-2 pt-3"
        v-html="card.content"
        v-if="card.content && card.content != '<p></p>'"
      ></div>

      <!-- Tasks -->
      <div class="py-2" v-if="card.tasks.length">
        <!-- <v-subheader class="  px-0">
          Checklist:
          <v-spacer></v-spacer>
          <v-icon
            size="14"
            class="pr-2"
            :color="card.tasks_done == card.tasks.length ? 'success' : 'blue'"
            >mdi-checkbox-multiple-marked</v-icon
          >
          <span class="caption">{{ card.tasks_done }}/{{ card.tasks.length }}</span>
        </v-subheader> -->
        <h5 class="pl-1 pb-2 pt-2 cardSubtitle" small>
          <!-- <v-icon
            small
            class="pr-2"
            :color="card.tasks_done == card.tasks.length ? 'success' : 'blue'"
            style="padding-bottom: 2px"
          >
            mdi-checkbox-multiple-marked
          </v-icon> -->
          {{ $t("misc.checklist") }} ({{ card.tasks_done }}/{{
            card.tasks.length
          }})
        </h5>

        <taskShow
          v-for="task in card.tasks"
          :key="task.id"
          :task="task"
          :currentMeetingId="currentMeetingId"
        />
      </div>

      <!-- Files -->
      <div class="py-4" v-if="card.files.length">
        <h5 class="pl-1 cardSubtitle" small>
          <v-icon
            small
            class="pr-2"
            color="dark_grey"
            style="padding-bottom: 2px"
          >
            mdi-paperclip
          </v-icon>
          {{ $t("feedItems.attachments") }} ({{ card.files.length }})
        </h5>
        <v-row wrap no-gutters>
          <v-col
            xs="12"
            sm="12"
            md="4"
            lg="4"
            v-for="file in card.files"
            :key="file.id"
            class="pa-2"
          >
            <fileItem :mobileVersion="true" :file="file" @openFile="showFile" />
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- Group right menu -->

    <v-card-actions class="">
      <!-- History -->
      <!-- <v-chip
        x-small
        color="light_grey"
        @click="$emit('seeHistoryUpdate', card.id)"
      >
        <v-icon x-small left>mdi-history</v-icon>
        See update history
      </v-chip> -->
      <v-chip x-small color="light_grey" class="px-2">
        <v-icon
          v-if="recentlyUpdated"
          size="16"
          color="success"
          style="left: -3px"
        >
          mdi-circle-medium
        </v-icon>
        <!-- <v-icon x-small left color="dark_grey">mdi-calendar-today</v-icon> -->
        {{
          card.last_update_at_meeting_name
            ? card.last_update_at_meeting_name + " - "
            : ""
        }}
        {{ timeAgoIfLessThanADay(card.updated_at) }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-chip label x-small class="px-2 mx-1" v-for="(tag, index) in card.tags.filter(t => {return t != null})" :key="'_tag'+index" dark  :color="tag.color">{{tag.name}}</v-chip>
      <!-- <span class="caption">{{ card.last_update_at_meeting_name }} - {{ card.updated_at | timeAgo }} </span> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import taskShow from "../tasks/task_show.vue";
import fileItem from "../shared/fileItem.vue";
import date from "@/mixins/date";

export default {
  name: "card_show",
  components: { taskShow, fileItem },
  mixins: [date],
  props: {
    card: {
      type: Object,
      required: true,
    },
    hover: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentMeetingId: {
      type: String,
      required: false,
      default: "",
    },
    recentlyUpdated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    sortedDecisions() {
      return this.card.decisions.sort((a, b) => {
        return a.position > b.position ? 1 : -1;
      });
    },
  },
  methods: {
    showFile(file) {
      this.$emit("showFile", file);
    },
  },
  watch: {},
};
</script>

<style scoped>
.leftSpacer {
  margin-left: 24px;
  margin-right: 24px;
}

.sectionTitle {
  height: 32px;
  width: 100%;
}
</style>
