<template>
  <v-container class="pa-0 cardIndexContainer">
    <v-row no-gutters wrap style="height: 100%">
      <v-col
        class="flex-grow-0 flex-shrink-1 hidden-md-and-down"
        cols="1"
        style="min-width: 300px; height: 100%"
        v-if="cards.length || archivedCards.length"
      >
        <div class="spacerCol"></div>
        <!-- Summary drawer -->
        <v-navigation-drawer
          permanent
          style="height: calc(100% - 96px)"
          floating
          color="transparent"
          width="300"
        >
          <!-- Note title -->
          <v-list
            dense
            nav
            class="pt-2 px-5 pb-0"
            style="overflow-y: auto"
            color="white"
          >
            <v-list-item style="background: white">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("misc.cards") }} ({{ cards.length }})</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- Cards -->
          <v-list
            dense
            nav
            class="py-0 px-3 pb-4"
            style="overflow-y: auto"
            color="white"
          >
            <v-list-item
              v-for="card in cards"
              :key="'smry_' + card.id"
              link
              style="min-height: 34px"
              @click="scrollToCard(card.id)"
            >
              <!-- <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon> -->

              <v-icon
                v-if="
                  currentMeetingId &&
                  card.last_update_at_meeting_id === currentMeetingId
                "
                size="16"
                color="success"
                style="position: absolute; left: -8px"
              >
                mdi-circle-medium
              </v-icon>
              <v-list-item-content class="pl-2">
                <!-- <v-list-item-title
                  :class="{
                    'font-weight-bold':
                      currentMeetingId &&
                      card.last_update_at_meeting_id === currentMeetingId,
                  }"
                >
                  {{ card.title ? card.title : card._title }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{
                    card.last_update_at_meeting_name
                      ? card.last_update_at_meeting_name + " - "
                      : ""
                  }}
                  {{ timeAgoIfLessThanADay(card.updated_at) }}
                </v-list-item-subtitle> -->

                <v-list-item-subtitle
                  :class="{
                    'font-weight-bold':
                      currentMeetingId &&
                      card.last_update_at_meeting_id === currentMeetingId,
                  }"
                >
                  {{ card.title ? card.title : $t("cards.noTitle") }}
                </v-list-item-subtitle>

                <!-- <v-list-item-subtitle class="caption" v-if=" currentMeetingId &&
                      card.last_update_at_meeting_id === currentMeetingId">
                  {{ timeAgoIfLessThanADay(card.updated_at) }}
                </v-list-item-subtitle> -->
              </v-list-item-content>
              <!-- <v-list-item-action-text style="font-size: 0.6rem"> {{ timeAgoIfLessThanADay(card.updated_at) }}</v-list-item-action-text> -->
            </v-list-item>
          </v-list>

          <!-- archivec cards -->

          <v-list
            v-if="archivedCards.length"
            dense
            nav
            class="py-0 px-3 pb-4"
            style="overflow-y: auto"
            color="white"
          >
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-content class="px-2">
                  <v-list-item-title>
                    {{ $t("cards.archivedItems") }} ({{
                      archivedCards.length
                    }})</v-list-item-title
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="card in archivedCards"
                :key="'smry_' + card.id"
                link
                style="min-height: 34px"
                class="px-2"
                @click="scrollToArchivedCard(card.id)"
              >
                <!-- <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon> -->

                <v-icon
                  v-if="
                    currentMeetingId &&
                    card.last_update_at_meeting_id === currentMeetingId
                  "
                  size="16"
                  color="success"
                  style="position: absolute; left: -4px; z-index: 10"
                >
                  mdi-circle-medium
                </v-icon>
                <v-list-item-content class="pl-2">
                  <!-- <v-list-item-title
                  :class="{
                    'font-weight-bold':
                      currentMeetingId &&
                      card.last_update_at_meeting_id === currentMeetingId,
                  }"
                >
                  {{ card.title ? card.title : card._title }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{
                    card.last_update_at_meeting_name
                      ? card.last_update_at_meeting_name + " - "
                      : ""
                  }}
                  {{ timeAgoIfLessThanADay(card.updated_at) }}
                </v-list-item-subtitle> -->

                  <v-list-item-subtitle
                    :class="{
                      'font-weight-bold':
                        currentMeetingId &&
                        card.last_update_at_meeting_id === currentMeetingId,
                    }"
                  >
                    {{ card.title ? card.title : $t("cards.noTitle") }}
                  </v-list-item-subtitle>

                  <!-- <v-list-item-subtitle class="caption" v-if=" currentMeetingId &&
                      card.last_update_at_meeting_id === currentMeetingId">
                  {{ timeAgoIfLessThanADay(card.updated_at) }}
                </v-list-item-subtitle> -->
                </v-list-item-content>
                <!-- <v-list-item-action-text style="font-size: 0.6rem"> {{ timeAgoIfLessThanADay(card.updated_at) }}</v-list-item-action-text> -->
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col
        cols="1"
        style="min-width: 100px; max-width: 100%; height: 100%"
        class="flex-grow-1 flex-shrink-0"
      >
        <div class="text-center spacerCol align-center d-flex justify-center">
          <v-btn
            color="button_blue"
            rounded
            small
            dark
            depressed
            @click="openNewCard"
            class="font-weight-bold"
          >
            <v-icon small left>mdi-plus</v-icon>
            {{ $t("misc.newCard") }}
          </v-btn>
        </div>
        <!-- Card index list -->
        <!-- <v-fab-transition>
          <v-btn
            v-show="cardDialog == false"
            color="button_blue"
            dark
            absolute
            bottom
            right
            fab
            style="position: absolute; bottom: 10px"
            depressed
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition> -->
        <v-row
          class="justify-center align-center fill-height"
          style="max-height: 600px"
          wrap
          no-gutters
          v-if="cards.length == 0 && archivedCards.length == 0"
        >
          <v-col cols="12" class="justify-center align-center text-center">
            <img
              class="centeredTemplateSvgs"
              src="/images/svg_images/emptynotes.svg"
              style="max-width: 100%"
            />

            <h3>
              {{
                $t("followUps." + (followUpId ? "followUpEmpty" : "topicEmpty"))
              }}
            </h3>
            <span class="body-3">
              {{ $t("textEditor.startByAdding") }}
            </span>
          </v-col>
        </v-row>

        <div
          v-else
          style="
            overflow-y: auto;
            height: calc(100% - 68px);
            padding-bottom: 10vh;
          "
          :id="'card_list_' + followUpId"
          class="mx-4"
        >
          <div v-for="card in cards" :key="'card_' + card.id">
            <cardWrapper
              :recentlyUpdated="
                !!(
                  currentMeetingId &&
                  card.last_update_at_meeting_id === currentMeetingId
                )
              "
              :card="card"
              :id="'div_' + card.id"
              @seeHistoryUpdate="seeHistoryUpdate"
              @showFile="showFile"
              @scrollToCard="scrollToCard"
              @openCardDialog="cardDialog = true"
              :currentMeetingId="currentMeetingId"
            />
          </div>

          <!-- <v-btn @click="displayArchived=!displayArchived" v-if="archivedCards.length">
            {{ $t("cards.archivedItems") }} ({{ archivedCards.length }})
          </v-btn> -->

          <v-expansion-panels
            flat
            accordion
            class="archivedCardIndex pt-2"
            v-model="displayArchived"
            v-if="archivedCards.length"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="pt-0 px-3">
                <span class="font-weight-medium"
                  ><v-icon small left style="top: -1px"
                    >mdi-archive-outline</v-icon
                  >{{ $t("cards.archivedItems") }} ({{
                    archivedCards.length
                  }})</span
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content id="div_archived_panel">
                <div v-for="card in archivedCards" :key="'card_' + card.id">
                  <cardWrapper
                    :recentlyUpdated="
                      !!(
                        currentMeetingId &&
                        card.last_update_at_meeting_id === currentMeetingId
                      )
                    "
                    :card="card"
                    :id="'div_' + card.id"
                    @seeHistoryUpdate="seeHistoryUpdate"
                    @showFile="showFile"
                    @scrollToCard="scrollToCard"
                    @openCardDialog="cardDialog = true"
                    :currentMeetingId="currentMeetingId"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
    <!-- New card Modal -->
    <v-dialog
      max-width="940"
      scrollable
      v-model="cardDialog"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <cardEditor
        :card="newCard"
        @closeDialog="cardDialog = false"
        @deleteCard="destroyCard"
        :currentMeetingId="currentMeetingId"
      />
    </v-dialog>

    <!-- History Modal -->
    <cardHistory
      :cardId="showCardHistoryId"
      @seeHistoryUpdate="seeHistoryUpdate"
    />
  </v-container>
</template>

<script>
import cardWrapper from "./card_wrapper.vue";
import cardHistory from "./card_history.vue";
import { mapActions, mapGetters } from "vuex";
import cardEditor from "./card_editor.vue";
import date from "@/mixins/date";
export default {
  name: "cards_index",
  mixins: [date],
  components: { cardWrapper, cardHistory, cardEditor },
  props: {
    topicId: {
      type: String,
      required: false,
    },
    followUpId: {
      type: String,
      required: true,
    },
    showFileViewerCol: {
      type: Boolean,
      required: false,
    },
    currentMeetingId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      displayArchived: null,
      showCardHistoryId: null,
      cardDialog: false,
      newCard: null,
    };
  },
  computed: {
    ...mapGetters(["topicCards", "followUpCards"]),
    allCards() {
      return this.followUpCards(this.followUpId).sort(this.sortByCreatedAt);
    },
    cards() {
      return this.allCards.filter((e) => !e.archived);
    },
    archivedCards() {
      return this.allCards.filter((e) => e.archived);
    },
  },
  methods: {
    ...mapActions([
      "setEditedCardId",
      "setEditedCardTopicId",
      "setEditedCardFollowUpId",
      "openDestroyDialog",
    ]),
    sortByCreatedAt(a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    },
    openNewCard() {
      this.setEditedCardId("new");
      if (this.followUpId) {
        this.setEditedCardTopicId("");
        this.setEditedCardFollowUpId(this.followUpId);
      } else {
        this.setEditedCardTopicId(this.topicId);
        this.setEditedCardFollowUpId("");
      }
      this.cardDialog = true;
    },
    scrollToCard(card_id) {
      this.$vuetify.goTo("#div_" + card_id, {
        container: "#card_list_" + this.followUpId,
        offset: -42,
      });
    },
    scrollToArchivedCard(card_id) {
      //Open the expansion panel to create dom elements
      this.displayArchived = 0;

      setTimeout(() => {
        this.scrollToCard(card_id);
      }, 250);
    },
    seeHistoryUpdate(event) {
      console.log("seeHistory event", event);
      this.showCardHistoryId = event;
    },
    showFile(file) {
      this.$emit("showFile", file);
    },
    destroyCard(card) {
      let msg = this.$t("cards.removeAlertMessageNoTitle");
      if (card.title)
        msg = this.$t("cards.removeAlertMessage").replace(
          "<card_title>",
          card.title
        );

      this.openDestroyDialog({
        id: card.id,
        model: "card",
        onDestroy: () => {
          this.cardDialog = false;
        },
        title: this.$t("cards.removeAlertTitle"),
        message: msg,
      });
    },
  },
  watch: {
    cards() {
      this.$nextTick(() => {
        // this.scrollToCard(this.cards[0].id);
      });
    },
  },
  mounted() {
    if (this.$route.query.scrollTo) {
      this.scrollToCard(this.$route.query.scrollTo);

      // Remove scroll query so it doesn't scroll everytime when refreshing the page
      let query = Object.assign({}, this.$route.query);
      delete query.scrollTo;
      this.$router.replace({ query });
    }
  },
};
</script>

<style scoped>
.cardIndexContainer {
  height: 100%;
}

.cardIndexRow {
  height: 100%;
}

.leftCol {
}

.rightCol {
  width: 100%;
}

.spacerCol {
  height: 56px;
  width: 100%;
}

/* .cardIndexWrapperGrid {
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-gap: 10px;
  height: inherit;

} */

/* .cardIndexWrapperGridOneCol {
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-gap: 10px;
  height: inherit;

} */
</style>
