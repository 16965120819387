export default {
    methods: {
        fileIconFromUrl(url) {
            return this.fileIconFromExtension(this.fileExtensionFromUrl(url));
        },
        fileNameFromUrl(url) {
            return decodeURI(url.substring(url.lastIndexOf("/") + 1))
        },
        fileExtensionFromUrl(url) {
            return (url.substring(url.lastIndexOf(".") + 1, url.length) || url);
        },
        fileIconFromExtension(extension) {
            switch (extension.toLowerCase()) {
                case "pdf":
                    return "../../../images/svg_icons/icon_file_pdf.svg";
                case "doc":
                    return "../../../images/svg_icons/icon_file_doc.svg";
                case "xlsx":
                    return "../../../images/svg_icons/icon_file_xlsx.svg";
                case "xls":
                    return "../../../images/svg_icons/icon_file_xlsx.svg";
                case "jpeg": case "jpg": case "png": case "gif": case "tiff": case "bmp":
                    return "../../../images/svg_icons/icon_file_img.svg";
                case "ppt":
                    return "../../../images/svg_icons/icon_file_ppt.svg";

                default:
                    return "../../../images/svg_icons/icon_file_unknown.svg";
            }
        },
        fileViewerType(extension) {
            switch (extension.toLowerCase()) {
                case "jpeg": case "jpg": case "png": case "gif": case "tiff": case "bmp":
                    return "LocalImageViewer";
                case "webm": case "mpeg4": case "3gpp": case "mov": case "avi": case "mpegps": case "wmv": case "flv":
                case "txt":
                case "css": case "html": case "php": case "c": case "cpp": case "h": case "hpp": case "js":

                case "ppt": case "pptx":
                case "pdf":
                case "pages":
                case "ai":
                case "psd":
                case "tiff":
                case "dxf":
                case "svg":
                case "eps": case "ps":
                case "ttf":
                case "xps":
                case "zip": case "rar":
                    return "GoogleDocsViewer";
                case "PPT":
                case "pptx":
                case "doc":
                case "docx":
                case "xls":
                case "xlsx":
                    return "OfficeWebAppsViewer";

                default:
                    return "fileNotSupported";
            }
        },
        fileViewersUrls(fileViewerType) {
            switch (fileViewerType) {
                case "OfficeWebAppsViewer":
                    return "https://view.officeapps.live.com/op/embed.aspx?src=[FILE_URL]";
                case "GoogleDocsViewer":
                    return "https://docs.google.com/viewer?embedded=true&url=[FILE_URL]"
                default:
                    return "";
            }
        },
        humanFileSize(bytes, si=true) {
            let u, b=bytes, t= si ? 1000 : 1024;
            ['', si?'k':'K', ...'MGTPEZY'].find(x=> (u=x, b/=t, b**2<1));
            return `${u ? (t*b).toFixed(1) : bytes} ${u}${!si && u ? 'i':''}B`;
          }
    }
};
