 <template>
  <div>
    <v-toolbar :class="{ meetingMargin: isInMeeting }" flat color="white" dense>
      <!-- <v-app-bar-nav-icon @click="$emit('clickOnBackIcon')">
        <v-icon>{{ backIcon }}</v-icon>
      </v-app-bar-nav-icon> -->
      <v-toolbar-title class="mr-8 body-1 almost_black--text ">
        <!-- <customIcon
          v-if="displayFollowupIcon"
          color="#545c74"
          class="mr-1"
          name="followup"
          style="position: relative; top: 3px"
          ><iconFollowup
        /></customIcon> -->


       <span class="font-weight-bold"> {{ subject }}</span>


        <v-chip v-if="followUp && followUp.project_id" small class="light_grey ml-4">
          <v-icon :color="followUp.project_color" size="13" class="mr-2"
            >mdi-folder</v-icon
          >
          {{ followUp.project_name }}
        </v-chip>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <followupNextMeetings v-if="followUp" :followUp="followUp" />

      <!-- Details popup -->
      <v-menu
        v-model="showDetailsPopUp"
        :close-on-content-click="false"
        offset-y
        :close-on-click="!editDetails"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            active-class="active_button"
            class="mx-2"
            small
            rounded
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="pr-2" small color="button_blue">
              mdi-card-text-outline
            </v-icon>
            {{ $t("meetings.details") }}
          </v-btn>
        </template>

        <v-card width="460">
          <v-list dense class="pa-0">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("meetings.details") }}:
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn v-if="false" small icon @click="editDetails = true">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-text class="pt-0">
            <v-textarea
              v-if="editDetails"
              dense
              filled
              :label="$t('misc.description')"
              v-model="tempDescription"
              flat
            ></v-textarea>
            <template v-else>
              <b class="caption"> {{ $t("misc.description") }}</b>

              <p
                v-text="
                  description
                    ? description
                    : $t('meetings.topicDescriptionNotDefined')
                "
              ></p>
            </template>
            <v-row wrap no-gutters>
              <b class="caption"> {{ $t("misc.owner") }}</b>
              <v-col cols="12">
                <div v-if="owner">
                  <userAvatar
                    :size="16"
                    :show_name_in_tooltip="false"
                    :first_name="owner.first_name"
                    :last_name="owner.last_name"
                    :avatar_url="owner.avatar_url"
                    :email="owner.email"
                  />
                  <span class="caption pl-2">{{
                    owner.first_name + " " + owner.last_name
                  }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <!--   <v-divider></v-divider>

         <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :disabled="!editDetails"
              text
              @click="closeShowDetailsPopUp()"
            >
              {{ $t("misc.cancel") }}
            </v-btn>
            <v-btn
              :disabled="!editDetails"
              color="primary"
              text
              @click="closeShowDetailsPopUp()"
            >
              {{ $t("misc.save") }}
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-menu>

      <!-- Files popup -->
      <!-- <v-menu
        v-model="showAssetsPopUp"
        :close-on-content-click="false"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            active-class="active_button"
            class="mx-2"
            small
            rounded
            text
            v-bind="attrs"
            v-on="on"
            >{{ $t("meetings.assets") }}
            <v-icon class="pl-2" small color="button_blue"
              >mdi-link-variant</v-icon
            ></v-btn
          >
        </template>

        <v-card width="460">
          <v-list dense class="pa-0">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("meetings.assets")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-text class="pt-0">
            <b class="caption">
              {{ pluralize($t("misc.file"), assets.length) }}
            </b>

            <fileItem
              class="my-1"
              v-for="file in assets"
              :file="file"
              :key="file.file_name"
            />
            <v-row class="py-4"></v-row> -->
      <!-- <b class="caption">
              {{ pluralize($t("misc.url"), fakeUrls.length) }}</b
            >
            <urlItem v-for="url in fakeUrls" :url="url" :key="url.name" /> -->
      <!-- </v-card-text>
          <template v-if="!followUp">
            <v-divider></v-divider>

            <v-card-actions> -->
      <!-- <v-btn
                class="mx-1"
                small
                depressed
                color="button_blue"
                dark
                @click="addAssetLink()"
              >
                <v-icon small class="pr-2">mdi-link-variant-plus</v-icon>
                {{ $t("meetings.addLink") }}
              </v-btn> -->

      <!-- <uploader v-on:upload="setTopicAssetBlob($event.file, $event.blob)">
                <template v-slot:btn>
                  <v-btn small depressed color="button_blue" dark>
                    <v-icon small class="pr-2">mdi-cloud-upload</v-icon>
                    {{ $t("meetings.addFile") }}
                  </v-btn>
                </template>
              </uploader>

              <span class="caption ml-2">{{
                $t("meetings.uploadRequierements")
              }}</span>
            </v-card-actions>
          </template>
        </v-card>
      </v-menu> -->
      <template v-if="!isInMeeting">
        <!-- <v-spacer></v-spacer>
        <span v-if="users.length" class="caption mr-2"
          >{{ $t("followUps.followupMembers") }}:</span
        >
        <userAvatarGroup
          class="hidden-sm-and-down"
          :users="users"
          :size="26"
          :label="$t('followUps.followupMembers')"
        /> -->
        <slot name="followupAddUsers" />
      </template>
      <!-- :disabledMenu="true" -->
      <!-- :users="topic.follow_up.follow_up_users.map((mu) => mu.user)" -->
    </v-toolbar>
    <v-divider v-if="divider"></v-divider>
  </div>
</template>

<script>
import date from "@/mixins/date";
import userAvatar from "../shared/userAvatar";
import userAvatarGroup from "../shared/userAvatarGroup";

import followupNextMeetings from "../followups/followupNextMeetings";
import fileItem from "@/components/shared/fileItem";
import pluralize from "pluralize";
import uploader from "../shared/uploader";
import { mapActions, mapGetters } from "vuex";

import UPDATE_TOPIC from "@/graphql/topics/update.gql";

import customIcon from "@/components/custom_icons/customIcon";
import iconFollowup from "@/components/custom_icons/iconFollowup";

export default {
  name: "topicToolbar",
  mixins: [date],
  components: {
    fileItem,
    followupNextMeetings,
    uploader,
    userAvatar,
    userAvatarGroup,
    customIcon,
    iconFollowup,
  },
  props: {
    meeting_topics: {
      type: Array,
    },
    subject: {
      type: String,
    },
    description: {
      type: String,
    },
    users: {
      type: Array,
      required: false,
      default: [],
    },
    owner: {
      type: Object,
      required: false,
      default: null,
    },
    followUp: {
      type: Object,
      required: false,
      default: null,
    },
    backIcon: {
      type: String,
    },
    displayFollowupIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isInMeeting: {
      type: Boolean,
      required: false,
      default: false,
    },
    divider: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      pluralize,
      showDetailsPopUp: false,
      editDetails: false,
      showAssetsPopUp: false,
      tempDescription: "",
    };
  },
  computed: {
    ...mapGetters(["selectedTopic", "selectedTopicId", "assetRefresh"]),
    assets() {
      this.assetRefresh;
      if (this.followUp) return this.followUpAssetsMap(this.followUp);
      return this.topicAssetsMap(this.selectedTopic);
    },
    fakeUrls() {
      return [];
    },
  },
  methods: {
    ...mapActions(["mergeAssetsToFollowUp"]),
    followUpAssetsMap(followup) {
      if (!followup.assets) return [];

      return followup.assets.map((url) => {
        return {
          filename: decodeURI(url.substring(url.lastIndexOf("/") + 1)),
          url: url,
        };
      });
      //return followup.topics.map(t => this.topicAssetsMap(t)).flat();
    },
    topicAssetsMap(topic) {
      if (topic.follow_up) return this.followUpAssetsMap(topic.follow_up);
      if (!topic.assets) return [];
      return topic.assets.map((url) => {
        return {
          filename: decodeURI(url.substring(url.lastIndexOf("/") + 1)),
          url: url,
        };
      });
    },
    closeShowDetailsPopUp() {
      this.showDetailsPopUp = false;
      this.editDetails = false;
      this.tempDescription = this.description;
    },
    addAssetLink() {},
    setTopicAssetBlob(file, blob) {
      if (!this.selectedTopicId) return this.sendError("Select a topic before");
      this.sendMutation("uploaded", "asset", UPDATE_TOPIC, {
        id: this.selectedTopic.id,
        asset_signed_blob_id: blob.signed_id,
      })
        .then((data) => {
          this.mergeAssetsToFollowUp({
            topic_id: this.selectedTopic.id,
            assets: data.update_topic.assets,
          });
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
  },
  created() {
    this.tempDescription = this.description;
  },
  beforeDestroy() {},
  watch: {
    description() {
      this.tempDescription = this.description;
    },
  },
};
</script>

<style>
.meetingMargin {
  margin-left: 64px;
}
</style>
