<template>
  <v-hover v-slot="{ hover }">
    <v-card class="mb-8 mx-2" flat color="transparent">
      <v-card
        flat
        class="leftSpacer rounded-lg pa-0 transparentBorder"
        :class="{ greyBorder: hover }"
        @click="toggleEditCard()"
        :ripple="false"
        style="cursor: pointer"
      >
        <v-btn
          style="position: absolute; right: 8px; top: 8px; z-index: 5"
          @click="toggleEditCard"
          v-if="hover"
          icon
          small
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <cardShow
          :card="card"
          :hover="hover"
          @seeHistoryUpdate="seeHistoryUpdate"
          @showFile="showFile"
          :currentMeetingId="currentMeetingId"
          :recentlyUpdated="recentlyUpdated"
        >
        </cardShow>
        <!--         <v-dialog max-width="840" scrollable v-model="editMode">
          <cardEditor :card="card" v-if="editMode" @closeDialog="editMode = false" />
        </v-dialog>
 -->
        <!-- Meta infos -->
      </v-card>
      <!-- <jsonViewer style="background-color: transparent":value="card" /> -->
    </v-card>
  </v-hover>
</template>

<script>
import { mapActions } from "vuex";
import cardShow from "./card_show.vue";
/* import cardEditor from "./card_editor.vue"; */

export default {
  name: "card_wrapper",
  components: { cardShow /*  cardEditor */ },
  props: {
    card: {
      type: Object,
      required: true,
    },
    currentMeetingId: {
      type: String,
      required: false,
      default: "",
    },
    recentlyUpdated: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      /* editMode: false, */
    };
  },
  methods: {
    ...mapActions(["setEditedCardId"]),
    seeHistoryUpdate(event) {
      this.$emit("seeHistoryUpdate", event);
    },
    toggleEditCard() {
      var selection = window.getSelection();
      if (selection.type != "Range") {
        this.setEditedCardId(this.card.id);
        this.$emit("openCardDialog");
      }

      /* this.editMode = !this.editMode;
      this.$emit("scrollToCard", this.card.id); */
    },
    showFile(file) {
      this.$emit("showFile", file);
    },
  },
};
</script>

<style scoped>
.leftSpacer {
  /* margin-left: 24px;
  margin-right: 24px; */
}

.sectionTitle {
  height: 32px;
  width: 100%;
}
</style>
