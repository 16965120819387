var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":"","wrap":"","align":"center"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"max-height":"600px","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"x-small":"","rounded":"","depressed":"","color":"light_grey dark_grey--text  "}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',{class:{
            'pr-1': _vm.selectedUsers.length,
          },attrs:{"x-small":""}},[_vm._v("mdi-account")]),_c('userAvatarGroup',{attrs:{"limit":1,"users":_vm.selectedUsers,"size":14,"disabledMenu":true,"avatar_border":false}})],1)]}}])},[_c('v-card',{staticClass:"px-2",attrs:{"flat":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("textEditor.assignTask"))+":")]),_c('searchAndSelectUsers',{attrs:{"selectedUsersIds":_vm.selectedUsers.map(function (e) { return e.id; }),"selectedMessage":_vm.$t('textEditor.assignedToTask')},on:{"onUserSelected":_vm.onUserSelected,"onUserUnselected":_vm.onUserUnselected}}),_c('v-list',{staticClass:"py-0",attrs:{"dense":"","two-lines":""}},_vm._l((_vm.selectedUsers),function(user){return _c('userItem',{key:user.id,attrs:{"user":user},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onUserUnselected(user)}}},[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-account-minus")])],1)]},proxy:true}],null,true)})}),1)],1)],1),_c('v-menu',{ref:'menu_date',attrs:{"offset-y":"","close-on-content-click":true,"return-value":_vm.selectedDate,"transition":"scale-transition"},on:{"update:returnValue":function($event){_vm.selectedDate=$event},"update:return-value":function($event){_vm.selectedDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var menu = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"x-small":"","rounded":"","depressed":"","color":"light_grey dark_grey--text "}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',{class:{
            'pr-2': _vm.date,
          },attrs:{"x-small":""}},[_vm._v("mdi-calendar-check")]),_vm._v(" "+_vm._s(_vm.date ? _vm.friendlyDate(_vm.date) : "")+" ")],1)]}}]),model:{value:(_vm.showDateMenu),callback:function ($$v) {_vm.showDateMenu=$$v},expression:"showDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.todaysDate},on:{"change":function($event){_vm.$refs['menu_date'].save(_vm.selectedDate);
        _vm.$emit('dateSelected', _vm.selectedDate);}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }